import React from "react";
import ProfileAvatar from "../Images/Avatar.png";
import ButtonLeftIcon from "../Components/ButtonLeftIcon";

export default function UserInfo({ userInfo, pseudo }) {
  return (
    <div className='hidden  sm:flex items-center justify-between gap-4 rounded-[15px] py-[17px] px-[22px] bg-bgColor border-[1px] border-borderColor max-h-[100px]'>
      <div className="flex items-center gap-[10px] ">
        <span className="rounded-[6px] border-[1px] border-[#191B22] bg-[#000000bf] h-[66px] w-[66px]">
          {userInfo?.photoURL ? (
            <img
              src={userInfo?.photoURL}
              alt=""
              className="w-full rounded-lg h-[100%] object-cover"
            />
          ) : (
            <img
              src={ProfileAvatar}
              alt=""
              className="w-full h-[100%] object-cover"
            />
          )}
        </span>
        <div className="flex flex-col gap-1">
          <div className="flex gap-[4px] ">
            <h2 className="text-[13px] font-medium text-white">
              {userInfo?.displayName}
            </h2>
            <span className="font-poppins text-[13px] font-medium leading-[1.4] text-[#ffffffb3]">
              @{pseudo}
            </span>
          </div>
          <a
            href="ryan.jebali@gmail.com"
            className="text-[#A0AEC0] underline text-[14px]"
          >
            {userInfo?.email}
          </a>
        </div>
      </div>
      <div className="w-[296px] h-[50px]">
        <ButtonLeftIcon
          text="Rejoindre la communauté discord"
          link="https://discord.gg/ZMPUNgMKgw"
        />
      </div>
    </div>
  );
}
