import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import toast from "react-hot-toast";
import DateTimePicker from 'react-datetime-picker';
import Datepicker from "react-tailwindcss-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Calendar, X } from "lucide-react";

const CouponsForm = ({ coupon, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    applicableTo: "one",
    discountType: "percentage",
    value: null,
    usageLimit: null,
    limitedTo: "",
    expiry: null,
    createdAt: null,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { updateCoupon, addCoupon } = useFirebase();

  useEffect(() => {
    if (coupon) {
      setFormData({
        title: coupon.title || "",
        discountType: coupon.discountType || "",
        value: +coupon.value,
        usageLimit: +coupon.usageLimit,
        applicableTo: coupon.applicableTo,
        limitedTo: coupon.limitedTo,
        expiry: new Date(coupon.expiry.toDate()),
        createdAt: new Date(coupon.createdAt.toDate()),
      });
    }
  }, [coupon]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };
  const handleApplicableToChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      applicableTo: value,
      limitedTo: "multiple", // Default to "multiple" when applicableTo is "multiple"
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.discountType)
      newErrors.discountType = "DiscountType is required";
    if (!formData.applicableTo)
      newErrors.applicableTo = "ApplicableTo is required";
    if (!formData.value) newErrors.value = "Value is required";
    if (!formData.usageLimit) newErrors.usageLimit = "UsageLimit is required";
    if (!formData.limitedTo) newErrors.limitedTo = "LimitedTo is required";
    if (!formData.expiry) newErrors.expiry = "Expiry is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      expiry: new Date(date),
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if ("adsd") {
    console.log("object")
      setIsLoading(true);
      try {
        const couponData = {
          title: formData.title,
          discountType: formData.discountType,
          applicableTo: formData.applicableTo,
          value: +formData.value,
          usageLimit: +formData.usageLimit,
          limitedTo: formData.limitedTo,
          expiry: formData.expiry,
          createdAt: Date.now(),
        };

        if (coupon) {
          const res = await updateCoupon(coupon.id, couponData);
          res.status === "success"
            ? toast.success(res.message, {
                position: "top-center",
              })
            : toast.error(res.message, {
                position: "top-center",
              });
        } else {
            if(formData.discountType === "percentage" && formData.value > 100 ){
                setErrors({value: "Percentage cannot be greater than 100"})
                return
            }
          const res = await addCoupon(couponData);
          res.status === "success"
            ? toast.success(res.message, {
                position: "top-center",
              })
            : toast.error(res.message, {
                position: "top-center",
              });
        }
        onClose();
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error(error, {
          position: "top-center",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
        console.log("form Not validated...")
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border text-white"
    >
      <h2 className="text-xl text-start font-medium py-4 border-b mb-6">
        {coupon ? "Update Coupon" : "Add Coupon"}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="block text-sm font-medium mb-1">
          Coupon Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-500">{errors.title}</p>
        )}
      </div>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="discountType" className="block text-sm font-medium">
          Discount Type
        </label>
        <select
          id="discountType"
          name="discountType"
          value={formData.discountType}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="percentage">Percentage</option>
          <option value="fixed">Fixed</option>
        </select>
      </div>

      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="value"
          className="block text-start text-sm font-medium mb-1"
        >
          Value
        </label>
        <input
          type="number"
          id="value"
          name="value"
          value={formData.value}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.value && (
          <p className="mt-1 text-sm text-red-500">{errors.value}</p>
        )}
      </div>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="applicableTo" className="block text-sm font-medium">
          Applicable To
        </label>
        <select
          id="applicableTo"
          name="applicableTo"
          value={formData.applicableTo}
          onChange={handleApplicableToChange}
          className="mt-1 block w-full border rounded p-2 bg-[#1C2536] border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="one">One</option>
          <option value="multiple">Multiple</option>
        </select>
      </div>

      {formData.applicableTo === "one" && (
        <div className="mb-4 flex-col items-start">
          <label
            htmlFor="limitedTo"
            className="block text-start text-sm font-medium mb-1"
          >
            Limited to
          </label>
          <input
            type="email"
            id="limitedTo"
            name="limitedTo"
            value={formData.limitedTo}
            onChange={handleChange}
            className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {errors.limitedTo && (
            <p className="mt-1 text-sm text-red-500">{errors.limitedTo}</p>
          )}
        </div>
      )}

      {formData.applicableTo !== "one" && (
        <div className="mb-4 flex-col items-start">
          <label
            htmlFor="usageLimit"
            className="block text-start text-sm font-medium mb-1"
          >
            Usage Limit
          </label>
          <input
            type="number"
            id="usageLimit"
            name="usageLimit"
            value={formData.usageLimit}
            onChange={handleChange}
            className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}

      {/* <div className="mb-4 flex-col items-start">
        <label
          htmlFor="expiry"
          className="block text-start text-sm font-medium mb-1"
        >
          Expiry Date
        </label>
        <DatePicker
          id="expiry"
          name="expiry"
          selected={formData.expiry}
          showTimeSelect
          showIcon
          calendarIconClassName="fill-white "
          calendarClassName="w-[100%]"
          onChange={handleDateChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.expiry && (
          <p className="mt-1 text-sm text-red-500">{errors.expiry}</p>
        )}
      </div> */}
      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="expiry"
          className="block text-start text-sm font-medium mb-1"
        >
          Expiry Date
        </label>
        {/* <Datepicker
          value={formData.expiry}
          id="expiry"
          name="expiry"
          minDate={new Date()}
          asSingle={true}
          useRange={false}
          selected={formData.expiry}
          onChange={handleDateChange}
          inputClassName="w-full p-2 text-white bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        /> */}
        <DateTimePicker
          value={formData.expiry}
          id="expiry"
          name="expiry"
          minDate={new Date()}
          calendarIcon={<Calendar/>}
          clearIcon={<X/>}
          selected={formData.expiry}
          calendarProps={{className: "bg-[#000000]"}}
          onChange={handleDateChange}
          className="w-full p-2 text-start text-white bg-[#1C2536] h-full rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.expiry && (
          <p className="mt-1 text-sm text-red-500">{errors.expiry}</p>
        )}
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 bg-primaryBlue text-white rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {isLoading
            ? "Processing..."
            : coupon
            ? "Update Coupon"
            : "Add Coupon"}
        </button>
      </div>
    </form>
  );
};

export default CouponsForm;
