import "./QuestionResponse.css";

import React from "react";
// import ReactPlayer from "react-player";

export default function QuestionResponse({
    responseResult,
    selectedOptionKey,
    selectedOptionTickedAnswer,
    rightDescription,
    wrongDescription,
    otherOption,
}) {
   
    console.log("explanation", rightDescription)
    console.log("explanation", wrongDescription)
    return (
        <div className="">
            {responseResult ? (
                <div className="flex flex-col gap-[20px]">
                    <div className="flex flex-col-reverse lg:flex-row lg:justify-between items-start justify-start gap-[30px] ">
                        <div className="response">
                            <span className="text-white text-start text-[14px] lg:text-[18px] font-medium">
                                Vous avez choisis la réponse :
                            </span>{" "}
                            <span className="text-[15px] lg:text-[18px] font-medium text-[#0075FF] ">{`${selectedOptionKey.replace("option","")}`}</span>
                        </div>

                        <h3 className="flex lg:p-[8px_15px] items-center text-[12px] rounded-[40px] p-2 font-medium bg-[#32BD9C]">
                            Bonne réponse !
                        </h3>
                    </div>

                    <div className="flex flex-col justify-start gap-[8px]">
                        <div className=" rounded-[12px] flex gap-[10px] items-center p-2 border border-[#32BD9C]">
                            <div className="flex w-[40px] h-[40px] justify-center items-center p-[10px] rounded-[8px] text-[15px] lg:text-[20px] font-medium text-white bg-[#32BD9C]">
                                {selectedOptionKey?.replace('option',"")}
                            </div>
                            <div className="text-white text-[13px] lg:text-[15px] font-normal">{selectedOptionTickedAnswer}</div>
                        </div>
                        {Object.keys(otherOption)
                            .filter((key) => key !== selectedOptionKey)
                            .map((key) => (
                                <div className="rounded-[12px] flex gap-[10px] items-center p-2 border border-[#442848]">
                                    <div className="flex w-[40px] h-[40px] justify-center items-center p-[10px] rounded-[8px] text-[15px] lg:text-[20px] font-medium bg-[#442848] text-[#EC6767]">
                                        {key.replace('option',"")}
                                    </div>
                                    <div className="text-white text-[13px] lg:text-[15px] font-normal">
                                        {otherOption[key]}
                                    </div>
                                </div>
                            ))}

                        <div className="text-[#A0AEC0] text-[12px] font-normal flex justify-start">
                            <h4 className="text-[#A0AEC0] text-left text-[12px] font-normal">{rightDescription}</h4>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-[20px]">
                    <div className="flex flex-col-reverse lg:flex-row lg:justify-between items-start justify-start gap-[10px]">
                        <div className="response">
                            <span className="text-white text-start text-[14px] lg:text-[18px] font-medium">
                                Vous avez choisis la réponse :
                            </span>{" "}
                            <span className="response-key">{`${selectedOptionKey.replace("option","")}`}</span>
                        </div>

                        <h3 className="flex lg:p-[8px_15px] items-center text-[12px] rounded-[40px] p-2 font-medium bg-[#EC6767]">Mauvaise réponse !</h3>
                    </div>
                    <div className="responsebtn-desc">
                        <div className="response-btn border border-[#EC6767]">
                            <div className="option-key text-white bg-[#EC6767]">
                                {selectedOptionKey.replace('option',"")}
                            </div>
                            <div className="ticked-answer">{selectedOptionTickedAnswer}</div>
                        </div>

                        {
                            Object.keys(otherOption)
                            .filter((key) => key !== selectedOptionKey)
                            .map((key) => (
                                <div className="rounded-[12px] flex gap-[10px] items-center p-2 border border-[#143d55]">
                                <div className="flex w-[40px] h-[40px] justify-center items-center p-[10px] rounded-[8px] text-[15px] lg:text-[20px] font-medium  bg-[#143d55] text-[#32BD9C]">
                                    {key.replace('option',"")}
                                </div>
                                <div className="text-white text-[13px] lg:text-[15px] font-normal">{otherOption[key]}</div>
                            </div>
                            ))
                        }
                        <div className="text-[#A0AEC0] text-[12px] font-normal flex justify-start">
                            <h4 className="text-[#A0AEC0] text-left text-[12px] font-normal">{wrongDescription}</h4>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
