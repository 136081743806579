
import React from 'react'



export default function RankingListItem({ rankingSerial, profileImage, profileName, userScore, userDesignation, myRankStyle }) {
    return (
     <>
        <div
      className={`flex justify-between items-center mt-5  lg:hidden `}
    >
      <div className="flex gap-1 md:gap-3 items-center">
        <h2 className="text-white text-right text-[15px] font-medium">
          {rankingSerial}
        </h2>
        <div className="w-[40px] h-[40px]">
          <img src={profileImage} alt="" className='rounded-lg' />
        </div>

        <div className="flex flex-col justify-start">
          <h2 className="text-white text-[12px] lg:text-[14px] font-medium leading-[1.4] text-left">
            {profileName}
          </h2>
         
        </div>
      </div>
      <div className="flex justify-start ">
        <div className="text-white font-poppins text-[14px] font-medium leading-[16px]">
          {userScore}
        </div>
        <div className="flex flex-col justify-center lg:items-center w-[50%] lg:w-full">
          <h3 className="hidden lg:flex  text-white text-[14px] font-medium leading-[1.4] text-left">
            {userDesignation || "Employee"}
          </h3>
         
        </div>
      </div>
       </div>
   
    <div className="hidden lg:flex flex-col justify-start gap-[30px] ">      
        <table className="w-full">
          <thead className="sticky top-0 bg-primary">
            <tr className="">
              <th className="text-start py-4 lg:ps-10 font-normal text-[10px] lg:text-[16px] text-[#A0AEC0] lg:text-white w-[62%] ">
                {/* PSEUDO */}
              </th>
              <th className="text-end lg:text-start py-4 font-normal text-[10px] lg:text-[16px] text-[#A0AEC0] lg:text-white w-[22%] ">
                {/* SCORE */}
              </th>
              <th className=" py-4 font-normal text-[#A0AEC0] w-[16%]">
                {/* FUNCTION */}
              </th>
            </tr>
          </thead>
          <tbody className="w-[100%]">
         
                <tr
                
                  className=""
                >
                  <td className="p-[10px_0px]">
                    <div className="flex gap-1 md:gap-0 items-center">
                      <span className="sm:mr-4 text-[#8491A0]">
                      {rankingSerial}
                      </span>
                      <img
                        src={profileImage}
                        alt={"name"}
                        className="w-10 h-10 rounded-lg mr-4"
                      />
                      <div>
                        <div className="text-start text-white text-[12px] lg:text-[16px] font-medium">
                          {profileName}
                        </div>
                    
                      </div>
                    </div>
                  </td>

                  <td className="text-start text-white text-[12px] md:text-[16px]">
                       {userScore}
                  </td>

                  <td className="hidden lg:flex lg:flex-col lg:justify-center  mt-4">
                    <div className='text-white'>{userDesignation || "Employee"}</div>
                  </td>
                </tr>
           
          </tbody>
        </table>
      </div>
     </>
 

       
    )
}
