import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import RewardLocked from "../RewardComponent/RewardLocked";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import { useGlobal } from "../context/GlobalContext";
import MyRewardsSkeleton from "../Skeleton/MyRewardsSkeleton";

export default function MyRewards({ setIsSidebarOpen, isSidebarOpen }) {
  const { userProgress } = useGlobal();
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const checkStatus = (id) => {
    return userProgress?.completedItems?.includes(id);
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onSnapshot(
      collection(db, "rewards"),
      (snapshot) => {
        const rewardsArray = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRewards(rewardsArray);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error getting rewards data:", error);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <MyRewardsSkeleton />;
  }

  return (
    <div className="">
      {/* Header */}
      <div className="flex flex-col">
        <Header
          PageName={"Récompenses"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>

      {/* Content */}
      <div className="bg-bgColor p-[22px] flex flex-col gap-[30px] rounded-[15px] border border-borderColor">
        {/* Level Progression */}
        <div className="rewards-content-header">
          <h2 className="text-left text-[20px] font-semibold text-white">
            Mon niveau de formation
          </h2>
          <h3 className="text-[14px] text-left text-[#A0AEC0] mb-[22]">
            Retrouvez ici toutes vos récompenses débloquées lors la formation.
          </h3>
        </div>

        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-5">
          {rewards?.map((reward) => (
            <div key={reward.id}>
              <RewardLocked
                lockedReason={reward?.unlockAfter}
                title={reward.name}
                url={reward.url}
                status={
                  checkStatus(reward.unlockAfter.id) ? "unlocked" : "locked"
                }
                detail={reward.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}