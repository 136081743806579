export const resultCriteria = {
  normalQuiz: {
    gradeD: 0,
    gradeC: 10,
    gradeB:15,
    gradeA: 20
  },
  moduleQuiz: {
    gradeD: 0,
    gradeC: 20,
    gradeB:35,
    gradeA: 40
  },
  sectionQuiz: {
    gradeD: 0,
    gradeC: 70,
    gradeB:90,
    gradeA: 100
  },
};