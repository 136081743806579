import React from 'react';

const QuestionsLoader = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="relative w-[120px] h-[120px]">
        <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 rounded-full animate-ping opacity-75"></div>
        <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 rounded-full animate-pulse"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-500 font-bold">
          Loading...
        </div>
      </div>
    </div>
  );
};

export default QuestionsLoader;