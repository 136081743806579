import React from 'react';

const QuizDialogue = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#080e2d] text-white border border-white h-[250px] rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Quitter le quiz en cours ?</h2>
        <p className="mb-6 text-gray-300">
          Êtes-vous sûr de vouloir quitter le quiz actuel ? Votre progression ne sera pas sauvegardée.
        </p>
        <div className="flex justify-between mt-14">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-transparent border border-white text-white rounded hover:bg-gray-700 transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizDialogue;