import React, { useState, useEffect } from 'react';
import { SvgIcon } from './SvgIcon';

const QuizTimer = ({ startTime, duration, onTimeUp }) => {
  const [timeRemaining, setTimeRemaining] = useState(duration);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const remainingTime = Math.max(duration  - Math.floor(elapsedTime / 1000), 0);
      setTimeRemaining(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(interval);
        onTimeUp();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, duration, onTimeUp]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes }:${remainingSeconds < 10? "0" + remainingSeconds: remainingSeconds}`;
  };

  return (
    <div className="flex items-center gap-3">
      <div className="flex items-center bg-white p-1 justify-center rounded">
        <SvgIcon name="clock" />
      </div>
      <div className="text-gray-200 text-md">{formatTime(timeRemaining)}</div>
    </div>
  );
};

export default QuizTimer;