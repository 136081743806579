import React, { useState } from 'react';
import { useFirebase } from '../context/firebaseContext';
import useUserRole from '../hooks/useUserRole';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';

const CreateQuestion = ({ onClose }) => {
  const [formData, setFormData] = useState({
    questionText: '',
    options: ['', ''],
    correctAnswer: '',
    explanation: '',
    difficulty: '', 
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { createQuestion } = useFirebase();
  const { user } = useUserRole();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...formData.options];
    newOptions[index] = value;
    setFormData(prevData => ({
      ...prevData,
      options: newOptions
    }));
  };

  const addOption = () => {
    if (formData.options.length < 4) {
      setFormData(prevData => ({
        ...prevData,
        options: [...prevData.options, '']
      }));
    }
  };

  const removeOption = (index) => {
    if (formData.options.length > 2) {
      const newOptions = formData.options.filter((_, i) => i !== index);
      setFormData(prevData => ({
        ...prevData,
        options: newOptions,
        correctAnswer: prevData.correctAnswer === `option${index + 1}` ? '' : prevData.correctAnswer
      }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.questionText) newErrors.questionText = 'Question is required';
    if (formData.options.filter(option => option.trim()).length < 2) {
      newErrors.options = 'At least 2 non-empty options are required';
    }
    if (!formData.correctAnswer) newErrors.correctAnswer = 'Correct answer is required';
    if (!formData.explanation) newErrors.explanation = 'Explanation is required';
    if (!formData.difficulty) newErrors.difficulty = 'Difficulty level is required'; 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const questionData = {
          questionText: formData.questionText
          ,
          options: formData.options.reduce((acc, option, index) => {
            acc[`option${index + 1}`] = option;
            return acc;
          }, {}),
          correctAnswer: formData.correctAnswer,
          explanation: formData.explanation,
          difficulty: formData.difficulty, 
          createdBy: user?.email.split("@")[0],
        };

        const res = await createQuestion(questionData);

        res.status === "success"
          ? toast.success(res.message, {
              position: 'top-center'
            })
          : toast.error(res.message, {
              position: 'top-center'
            });
        onClose();

      } catch (error) {
        toast.error(error, {
          position: 'top-center'
        });

      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border overflow-y-auto h-[850px] scrollbar-thin scrollbar-thumb-[#101490] scrollbar-thumb-rounded-md">
      <h2 className="text-xl text-start font-medium text-white py-4 border-b mb-6">Add Question</h2>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="questionText" className="text-lg font-normal text-white mb-1">Question Text</label>
        <input
          type="text"
          id="questionText"
          name="questionText"
          value={formData.questionText}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter the question"
        />
        {errors.questionText && <p className="mt-1 text-sm text-red-500">{errors.questionText}</p>}
      </div>

      <div className='mb-4  w-full'>
        <div className='flex justify-between items-center w-full my-2 '>
          <label className="text-lg font-normal text-white mb-1">Options</label>
          {formData.options.length < 4 && (
            <button
              type="button"
              onClick={addOption}
              className="mt-2 px-4 py-2 bg-[#1a2b56] text-white rounded-md hover:bg-[#243772]"
            >
              Add Option
            </button>
          )}
        </div>
        {formData.options.map((option, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              className="flex-grow w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={`Option ${index + 1}`}
            />
            {formData.options.length > 2 && (
              <button
                type="button"
                onClick={() => removeOption(index)}
                className="ml-2 p-1 text-white hover:text-red-500"
              >
                <X size={20} />
              </button>
            )}
          </div>
        ))}

        {errors.options && <p className="mt-1 text-sm text-red-500">{errors.options}</p>}
      </div>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="correctAnswer" className="text-lg font-normal text-white mb-1">Correct Answer</label>
        <select
          id="correctAnswer"
          name="correctAnswer"
          value={formData.correctAnswer}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select correct answer</option>
          {formData.options.map((_, index) => (
            <option key={index} className='text-[#000]' value={`option${index + 1}`}>Option {index + 1}</option>
          ))}
        </select>
        {errors.correctAnswer && <p className="mt-1 text-sm text-red-500">{errors.correctAnswer}</p>}
      </div>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="explanation" className="text-lg font-normal text-white mb-1">Explanation</label>
        <textarea
          id="explanation"
          name="explanation"
          value={formData.explanation}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Provide an explanation for the correct answer"
          rows="4"
        />
        {errors.explanation && <p className="mt-1 text-sm text-red-500">{errors.explanation}</p>}
      </div>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="difficulty" className="text-lg font-normal text-white mb-1">Difficulty</label>
        <select
          id="difficulty"
          name="difficulty"
          value={formData.difficulty}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select difficulty</option>
          <option value="facile" className="text-black">Facile</option>
          <option value="intermediate" className="text-black">Intermediate</option>
          <option value="difficile" className="text-black">Difficile</option>
        </select>
        {errors.difficulty && <p className="mt-1 text-sm text-red-500">{errors.difficulty}</p>}
      </div>

      <div className="flex justify-end space-x-3 mt-6">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </form>
  );
};

export default CreateQuestion;
