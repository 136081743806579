import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import useUserRole from "../hooks/useUserRole";
import { collection, getDocs, query, where } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import { db } from "../firebase/config";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
const QuizForm = ({ quiz, onClose }) => {
  const storage = getStorage();
  const [formData, setFormData] = useState({
    title: "",
    order: "",
    totalMarks: "",
    status: "inactive",
    description: "",
    difficulty: "",
    duration: "",
    createdBy: "",
    type: "quiz",
    module: { title: "", moduleId: "" },
    thumbnailUrl: "",
    quizType: "normalQuiz",
    level: "",
    quizNumber: "",
    sectionId: ""
  });
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [availableModules, setAvailableModules] = useState([]);
  const [quizNumberOptions, setQuizNumberOptions] = useState([]);
  const { addQuiz, updateQuiz } = useFirebase();

  const { user } = useUserRole();


  const fetchModules = async () => {
    try {
      const moduleCollection = await getDocs(collection(db, "modules"));
      const moduleData = moduleCollection.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        section: doc.data().section,
      }));
      setAvailableModules(moduleData);
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  useEffect(() => {
    fetchModules();
    if (quiz) {
      setFormData({
        title: quiz.title,
        order: quiz.order,
        totalMarks: quiz.totalMarks,
        difficulty: quiz.difficulty,
        description: quiz.description,
        status: quiz.status,
        createdBy: quiz.createdBy,
        type: quiz.type,
        duration: quiz.duration,
        module: quiz.module || { title: "", moduleId: "" },
        thumbnailUrl: quiz.thumbnailUrl || "",
        quizType: quiz.quizType || "normalQuiz",
        level: quiz.level,
        sectionId: quiz?.sectionId,
        quizNumber: quiz?.quizNumber || "quiz001",
      });
    }
  }, [quiz]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModuleChange = async (e) => {
    const selectedModuleId = e.target.value;
    const selectedModule = availableModules.find(
      (module) => module.id === selectedModuleId
    );
    console.log("selected Module", selectedModuleId)
    setFormData((prevData) => ({
      ...prevData,
      module: {
        title: selectedModule?.title || "",
        moduleId: selectedModule?.id || "",
      },
      sectionId: selectedModule.section.sectionId
    }));

    // Fetch questions for the selected module
    if (selectedModuleId) {
      try {
        const questionsQuery = query(
          collection(db, "questions"),
          where("moduleId", "==", selectedModuleId)
        );
        const questionsSnapshot = await getDocs(questionsQuery);
        const quizNumbers = new Set();
      
        questionsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          console.log("data", data)
          if (data.quizNumber || data.quizTitle) {
            quizNumbers.add(`${data.quizNumber}-${data.quizTitle}`);
          }
        });
        console.log("quiz Nymber",Array.from(quizNumbers) )
        setQuizNumberOptions(Array.from(quizNumbers));
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("Failed to fetch quiz numbers");
      }
    }
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnailFile(file);
    }
  };

  const uploadThumbnail = async (file) => {
    if (!file) return null;
    const uuid = uuidv4;
    const fileRef = ref(storage, `thumbnails/${uuid}-${file.name}`);
    await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(fileRef);
    console.log("download", downloadURL);
    return downloadURL;
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.description) newErrors.description = "Description is required";
    if (!formData.order) newErrors.order = "Order is required";
    if (!formData.status) newErrors.status = "Status is required";
    if (!formData.difficulty) newErrors.difficulty = "Difficulty is required";
    if (!formData.duration) newErrors.duration = "Duration is required";
    if (!formData.totalMarks) newErrors.totalMarks = "Total Marks is required";
    if (!formData.module.moduleId) newErrors.module = "Module selection is required";
    // if (!thumbnailFile && !formData.thumbnailUrl) newErrors.thumbnailUrl = "Thumbnail is required";
    if (formData.quizType === "normalQuiz" && !formData.level) newErrors.level = "Level is required";
    if (!formData.quizNumber) newErrors.quizNumber = "Quiz Number is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        let thumbnailUrl = formData.thumbnailUrl;
        if (thumbnailFile) {
          thumbnailUrl = await uploadThumbnail(thumbnailFile);
          console.log(thumbnailUrl, "ThumnailFile");
        }

        let quizData = {
          title: formData.title,
          order: +formData.order,
          description: formData.description,
          difficulty: formData.difficulty,
          createdBy: user?.email.split("@")[0],
          totalMarks: formData.totalMarks,
          status: formData.status,
          type: formData.type,
          duration: formData.duration,
          quizType: formData.quizType,
          module: formData.module,
          thumbnailUrl: thumbnailUrl,
          quizNumber: formData.quizNumber,
          sectionId: formData.sectionId
        };

        if (formData.quizType === "normalQuiz") {
          quizData.level = formData.level;
        }
        if (quiz) {
          const res = await updateQuiz(quiz.id, quizData);

          res.status === "success"
            ? toast.success(res.message, {
                position: "top-center",
              })
            : toast.error(res.message, {
                position: "top-center",
              });
        } else {
          const res = await addQuiz("quizes", quizData);
          res.status === "success"
            ? toast.success(res.message, {
                position: "top-center",
              })
            : toast.error(res.message, {
                position: "top-center",
              });
        }

        onClose();
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border overflow-y-auto h-[850px] scrollbar-thin scrollbar-thumb-[#101490] scrollbar-thumb-rounded-md"
    >
      <h2 className="text-xl text-start font-medium text-white py-4 border-b mb-6">
        {quiz ? "Update Quiz" : "Add Quiz"}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="text-lg font-normal text-white mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Quiz Title"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-500">{errors.title}</p>
        )}
      </div>


      <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="description"
          className="text-start text-lg font-normal text-white mb-1"
        >
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          rows="3"
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Quiz description"
        ></textarea>
        {errors.description && (
          <p className="mt-1 text-sm text-red-500">{errors.description}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="module" className="text-lg font-normal text-white mb-1">
          Module
        </label>
        <select
          id="module"
          name="module"
          value={formData.module.moduleId}
          onChange={handleModuleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>
            Select a module
          </option>
          {availableModules.map((module) => (
            <option key={module.id} value={module.id} className="text-black">
              {module.title} - {module.section.title}
            </option>
          ))}
        </select>
        {errors.module && (
          <p className="mt-1 text-sm text-red-500">{errors.module}</p>
        )}
      </div>
     <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="quizType"
          className="text-lg font-normal text-white mb-1"
        >
          Quiz Type
        </label>
        <select
          id="quizType"
          name="quizType"
          value={formData.quizType}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option disabled className="text-gray-600">
            Select quiz type
          </option>
          <option value="normalQuiz" className="text-gray-600">
            Normal Quiz
          </option>
          <option value="sectionQuiz" className="text-gray-600">
            Section Quiz
          </option>
          <option value="moduleQuiz" className="text-gray-600">
            Module Quiz
          </option>
        </select>
        {errors.quizType && (
          <p className="mt-1 text-sm text-red-500">{errors.quizType}</p>
        )}
      </div>
      {formData.quizType === "normalQuiz" && (
        <div className="mb-4 flex flex-col items-start">
          <label htmlFor="level" className="text-lg font-normal text-white mb-1">
            Level
          </label>
          <select
            id="level"
            name="level"
            value={formData.level}
            onChange={handleChange}
            className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="" disabled>
              Select a level
            </option>
            <option value="niveau1" className="text-black">
              Niveau 1
            </option>
            <option value="niveau2" className="text-black">
              Niveau 2
            </option>
            <option value="niveau3" className="text-black">
              Niveau 3
            </option>
          </select>
          {errors.level && (
            <p className="mt-1 text-sm text-red-500">{errors.level}</p>
          )}
        </div>
      )}
      {
        formData.quizType !== "moduleQuiz" &&  <div className="mb-4 flex flex-col items-start">
        <label htmlFor="quizNumber" className="text-lg font-normal text-white mb-1">
          Quiz Number
        </label>
        <select
          id="quizNumber"
          name="quizNumber"
          value={formData.quizNumber}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>Select a quiz number</option>
          {quizNumberOptions.map((option, index) => (
            <option key={index} value={option.split("-")[0]} className="text-black">
              {option}
            </option>
          ))}
        </select>
        {errors.quizNumber && (
          <p className="mt-1 text-sm text-red-500">{errors.quizNumber}</p>
        )}
      </div>
      }
      
      <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="difficulty"
          className="text-lg font-normal text-white mb-1"
        >
          Difficulty
        </label>
        <select
          id="difficulty"
          name="difficulty"
          value={formData.difficulty}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>
            Select a difficulty
          </option>
          <option value="facile" className="text-black">
            Facile
          </option>
          <option value="intermédiaire" className="text-black">
            intermédiaire
          </option>
          <option value="difficile" className="text-black">
            Difficile
          </option>
        </select>
        {errors.difficulty && (
          <p className="mt-1 text-sm text-red-500">{errors.difficulty}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="order" className="text-lg font-normal text-white mb-1">
          Duration (minutes)
        </label>
        <input
          type="number"
          id="duration"
          name="duration"
          value={formData.duration}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="duration"
        />
        {errors.order && (
          <p className="mt-1 text-sm text-red-500">{errors.duration}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="order" className="text-lg font-normal text-white mb-1">
          Order
        </label>
        <input
          type="number"
          id="order"
          name="order"
          value={formData.order}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Quiz Order"
        />
        {errors.order && (
          <p className="mt-1 text-sm text-red-500">{errors.order}</p>
        )}
      </div>

      <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="totalMarks"
          className="text-lg font-normal text-white mb-1"
        >
          Total Marks
        </label>
        <input
          type="text"
          id="totalMarks"
          name="totalMarks"
          value={formData.totalMarks}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Total marks"
        />
        {errors.totalMarks && (
          <p className="mt-1 text-sm text-red-500">{errors.totalMarks}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="status" className="text-lg font-normal text-white mb-1">
          Status
        </label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>
            Select a status
          </option>
          <option value="locked" className="text-black">
            Locked
          </option>
          <option value="unlocked" className="text-black">
            Un-locked
          </option>
          <option value="inactive" className="text-black">
            Inactive
          </option>
          <option value="completed" disabled className="text-gray-500">
            Completed
          </option>
        </select>
        {errors.status && (
          <p className="mt-1 text-sm text-red-500">{errors.status}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="thumbnailUrl"
          className="text-lg font-normal text-white mb-1"
        >
          Upload Thumbnail
        </label>
        <input
          type="file"
          id="thumbnailUrl"
          name="thumbnailUrl"
          onChange={handleFileChange}
          accept="image/*"
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.thumbnailUrl && (
          <p className="mt-1 text-sm text-red-500">{errors.thumbnailUrl}</p>
        )}
        {formData.thumbnailUrl && (
          <img
            src={formData.thumbnailUrl}
            alt="Current thumbnail"
            className="mt-2 w-20 h-10 object-cover"
          />
        )}
      </div>

      <div className="flex">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-6 py-2 bg-primaryBlue text-white rounded-lg font-normal ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-primaryBlue"
          }`}
        >
          {isLoading ? "Processing..." : quiz ? "Update Quiz" : "Add Quiz"}
        </button>
      </div>
    </form>
  );
};

export default QuizForm;
