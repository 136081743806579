import React from 'react';

const BenefitsSkeleton = () => (
  <div className="animate-pulse space-y-8 text-white px-4 w-full">
    {/* Image placeholder */}
    <div className="flex mx-auto justify-center mb-4">
      <div className="w-[400px] h-[200px] bg-gray-200/20 rounded-lg" />
    </div>
    
    {/* Heading placeholders */}
    <div className="space-y-4">
      <div className="h-12 bg-gray-200/20 rounded-lg w-3/4" />
      <div className="h-8 bg-gray-200/20 rounded-lg w-1/2" />
    </div>
    
    {/* Features list placeholders */}
    <div className="space-y-4 mt-10 grid grid-cols-1 md:grid-cols-2">
      {[...Array(8)].map((_, index) => (
        <div key={index} className="flex items-start gap-2">
          {/* Checkbox circle placeholder */}
          <div className="mt-1 w-6 h-6 rounded-full bg-gray-200/20" />
          {/* Feature text placeholder */}
          <div className="h-6 bg-gray-200/20 rounded w-3/4" />
        </div>
      ))}
    </div>
    
    {/* Bottom text placeholder */}
    <div className="mt-8">
      <div className="h-8 bg-gray-200/20 rounded-lg w-5/6" />
    </div>
  </div>
);

export default BenefitsSkeleton;