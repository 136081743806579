import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Images/Logo.svg";
import SuccessMark from "../Images/payment-success.png";
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
const ENV = process.env.REACT_APP_ENV;

const PaymentSuccess = () => {
  const location = useLocation();
  const [responseMessage, setResponseMessage] = useState("");

  const trigerGoogleTags = (clientSecret, paymentIntent) => {
    if (window.gtag && ENV === "production") {
      try {
        window.gtag("event", "conversion", {
          send_to: "AW-16489125436/13yKCN2on50ZELys0LY9",
          transaction_id: clientSecret.split("_secret_")[0],
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });

        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: clientSecret.split("_secret_")[0],
            value: paymentIntent.amount || 0,
            currency: paymentIntent.currency,
          },
        });

        window.gtag("event", "purchase", {
          transaction_id: clientSecret.split("_secret_")[0],
          value: paymentIntent.amount || 0,
          currency: paymentIntent.currency,
        });
      } catch (e) {
        console.log("Google analytics tracking error", e);
      }
    }
  };
  const searchParams = new URLSearchParams(location.search);
const orderId = searchParams.get("token");
const hasFetched = useRef(false); // Tracks whether the request has been sent

useEffect(() => {
  let isMounted = true; // Tracks whether the component is still mounted

  const fetchPaymentData = async () => {
    if (!orderId || hasFetched.current || !isMounted) return;
    hasFetched.current = true; // Ensure only one request is made

    try {
      const functions = getFunctions();
      const confirmPaypalPayment = httpsCallable(functions, "confirmPaypalPayment");
      const result = await confirmPaypalPayment({ orderId });
      const data = result.data;
      if (isMounted) console.log("Data: ", data); // Ensure this runs only if mounted
    } catch (err) {
      if (isMounted) toast.error("Error updating payment information. Please try again.");
    }
  };

  fetchPaymentData();

  return () => {
    isMounted = false; // Prevent actions on unmounted component
  };
}, [orderId]);



  useEffect(() => {
    const clientSecret = location.state?.client_secret;
    const paymentIntent = location.state?.paymentIntent;
    if (clientSecret && paymentIntent) {
      trigerGoogleTags(clientSecret, paymentIntent);
    }
  }, []);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row">
      {/* Left Panel */}
      <div className="bg-[#03163F] w-full md:w-1/2 p-10 flex justify-center items-center">
        <div className="flex flex-col items-center justify-center gap-6 w-full">
          <div className=" flex items-center gap-2">
            <img src={Logo} width={80} height={80} alt="Logo" />
            <div className="flex flex-col text-white font-normal">
              <span>ChatGPT</span>
              <span>Academy</span>
            </div>
          </div>
          <div className="text-white flex flex-col items-center">
            <h1 className="text-3xl font-bold mb-4">
              Bienvenue dans la Chatgpt Academy !
            </h1>
            <div className="flex flex-col items-center justify-center w-[70%]">
              <p className="text-xl w-full ">
                Votre inscription est bien confirmée !
              </p>
              <p className="text-xl w-full ">
                Vous êtes prêt(e) à rejoindre l'aventure !
              </p>
            </div>
            <div className="bg-white/10 rounded-lg p-6 w-full my-10">
              <h2 className="text-white text-start mb-6 text-xl font-semibold ">
                Prochaines étapes:
              </h2>
              <ol className="text-white italic ms-4 space-y-2 list-decimal">
                <li className="list-item text-sm  text-start">
                  Connectez-vous à votre compte
                </li>
                <li className="list-item text-sm text-start">
                  Complétez votre profil
                </li>
                <li className="list-item text-sm text-start">
                  Commencez votre formation
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Right Panel */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-10">
        <div className="max-w-md w-full">
          <div className="text-center mb-8">
            <img className="mx-auto w-[85px] h-[85px] mb-4" src={SuccessMark} />
            <h2 className="text-2xl font-bold text-[#30313D] mb-4">
              Paiement réussi !
            </h2>
            <p className="text-gray-600">
              Merci pour votre confiance. Un email de confirmation a été envoyé
              à votre adresse.
            </p>
          </div>

          <div className="bg-white rounded-[30px] shadow-lg p-6 mb-6">
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-600">Status</span>
              <span className="text-green-500 font-semibold">Confirmé</span>
            </div>
            <div className="w-full h-[1px] bg-gray-200 mb-4"></div>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Formation</span>
                <span className="font-semibold">Chatgpt Academy</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Accès</span>
                <span className="font-semibold">Immédiat</span>
              </div>
            </div>
          </div>

          <Link
            to="/login"
            className="w-full block text-center py-3 px-4 rounded-[16px] shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          >
            se connecter
          </Link>

          <p className="mt-6 text-center text-sm text-gray-600">
            Des questions?{" "}
            <a
              href="https://discord.com/invite/ZMPUNgMKgw"
              target="_blank"
              className="text-indigo-600 hover:text-indigo-500"
            >
              &nbsp; Rejoignez notre Discord
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
