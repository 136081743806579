import React from 'react'
export default function Modal({children, onClose}) {

  
  return (
    <div className='fixed modal-backdrop top-0 left-0 w-[100%] h-[100%] bg-[#000000c4]'>
      <div className="relative p-[30px] w-[50%] mx-auto bg-transparent overflow-y-auto">
        <button 
          onClick={onClose} 
          className="absolute top-16 right-16 text-white hover:text-gray-300 bg-blue-800 rounded-full p-1"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            className="w-6 h-6"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </button>
        {children}
      </div>
    </div>
  )
}
