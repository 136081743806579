import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import {
  Pencil,
  Trash2,
  Check,
  X,
  ChevronDown,
  ChevronUp,
  Plus,
} from "lucide-react";
import Modal from "../Components/Modal";
import CreateQuestion from "../Components/CreateQuestion";
import Header from "../Components/Header";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import toast from "react-hot-toast";

const AllQuestions = ({isSidebarOpen, setIsSidebarOpen}) => {
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { writeQuestion, removeQuestion} = useFirebase();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "questions"),
      (snapshot) => {
        const questions = snapshot.docs.map((q) => ({ id: q.id, ...q.data()}));
        setQuestions(questions)
      },
      (error) => {
        return error
      }
    );

   return () => unsubscribe()
  }, []);

  useEffect(() => {
    const results = questions?.filter(
      (question) =>
        question.questionText
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        question.createdBy?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(results);
  }, [searchTerm, questions]);


  const handleDelete = async (questionId) => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      await removeQuestion(questionId);
    }
  };

  const handleEdit = (question) => {
    setEditingId(question.id);
    setEditForm({
      questionText: question.questionText,
      options: question.options,
      correctAnswer: question.correctAnswer,
      explanation: question.explanation,
      difficulty: question.difficulty,
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
  };
 
  const handleUpdateQuestion = async () => {
    console.log("handle Update")
    const res = await writeQuestion(editingId, editForm);
    if(res.status === "success"){
      toast.success(res.message)
    } else{
      toast.error(res.message)
    }
    setEditingId(null);
    setEditForm({});
  };

  const handleEditFormChange = (e, field, optionKey) => {
    if (field === "options") {
      setEditForm((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          [optionKey]: e.target.value,
        },
      }));
    } else {
      setEditForm((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    }
  };

  const toggleExpand = (id) => {
    setExpandedQuestion(expandedQuestion === id ? null : id);
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  return (
    <div className="w-full rounded-xl h-screen">
      <div className="flex flex-col">
        <Header
          PageName={"allquestion"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="w-full bg-[#060B26] border-[#ffffff55] border text-white p-6 rounded-xl h-[90%] relative">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">All Questions</h2>
          <button
            onClick={openCreateModal}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center"
          >
            <Plus size={20} className="mr-2" /> Add Question
          </button>
        </div>

        <div className="mb-6 w-fit">
          <input
            type="text"
            placeholder="Search questions..."
            className="w-full px-4 py-2 bg-[#1C2536] border border-[#2E3A59] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="space-y-2 my-6 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-thumb-rounded  h-[85%]">
          {filteredQuestions?.map((question, index) => (
            <div
              key={question.id}
              className="bg-[#131B31] rounded-lg overflow-hidden"
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex-1 mr-4">
                  {editingId === question.id ? (
                    <input
                      type="text"
                      value={editForm.questionText}
                      onChange={(e) => handleEditFormChange(e, "questionText")}
                      className="w-full px-2 py-1 bg-[#1C2536] rounded"
                    />
                  ) : (
                    <div className="flex items-center gap-6">
                      <h2>#{index + 1} </h2>
                      <h3 className="text-lg font-medium">
                        {question.questionText}
                      </h3>
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  {editingId === question.id ? (
                    <>
                      <button
                        onClick={handleUpdateQuestion}
                        className="p-1 text-green-500 hover:text-green-400"
                      >
                        <Check size={20} />
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        className="p-1 text-red-500 hover:text-red-400"
                      >
                        <X size={20} />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEdit(question)}
                        className="p-1 text-blue-500 hover:text-blue-400"
                      >
                        <Pencil size={20} />
                      </button>
                      <button
                        onClick={() => handleDelete(question.id)}
                        className="p-1 text-red-500 hover:text-red-400"
                      >
                        <Trash2 size={20} />
                      </button>
                    </>
                  )}
                  <button
                    onClick={() => toggleExpand(question.id)}
                    className="p-1 text-gray-400 hover:text-white"
                  >
                    {expandedQuestion === question.id ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </button>
                </div>
              </div>

              {expandedQuestion === question.id && (
                <div className="px-4 pb-4">
                  <div className="mb-2">
                    <div className="grid grid-cols-4 gap-4">
                      {Object.entries(question.options).map(([key, value]) => (
                        <div key={key} className="bg-[#1C2536] p-2 rounded">
                          <h5 className="text-sm font-medium text-gray-400 mb-2">
                            {key}
                          </h5>
                          {editingId === question.id ? (
                            <input
                              type="text"
                              value={editForm.options[key]}
                              onChange={(e) =>
                                handleEditFormChange(e, "options", key)
                              }
                              className="w-full px-2 py-1 bg-[#2E3A59] rounded"
                            />
                          ) : (
                            <p className="text-sm">{value}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h4 className="font-medium mb-1 text-start text-gray-400 ">Correct Answer:</h4>
                    {editingId === question.id ? (
                      <select
                        value={editForm.correctAnswer}
                        onChange={(e) =>
                          handleEditFormChange(e, "correctAnswer")
                        }
                        className="w-full px-2 py-1 bg-[#1C2536] rounded"
                      >
                        {Object.keys(editForm.options).map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <p className="text-green-500 text-start">{question.correctAnswer}</p>
                    )}
                  </div>
                  {editingId === question.id ? (
                    <div className="mt-4">
                      <h4 className="font-medium mb-1 text-start text-gray-400">Explanation:</h4>
                      <textarea
                        value={editForm.explanation}
                        onChange={(e) => handleEditFormChange(e, "explanation")}
                        className="w-full px-2 py-1 bg-[#1C2536] rounded"
                        rows="3"
                      />
                    </div>
                  ) : (
                    question.explanation && (
                      <div className="mt-4">
                        <h4 className="font-medium mb-1 text-start text-gray-400">Explanation:</h4>
                        <p className="text-sm text-start">{question.explanation}</p>
                      </div>
                    )
                  )}
                  <div className="mt-4">
                    <h4 className="font-medium mb-1 text-start text-gray-400">Difficulty:</h4>
                    {editingId === question.id ? (
                      <select
                        value={editForm.difficulty}
                        onChange={(e) => handleEditFormChange(e, "difficulty")}
                        className="w-full px-2 py-1 bg-[#1C2536] rounded"
                      >
                        <option value="facile">Facile</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="difficile">Difficile</option>
                      </select>
                    ) : (
                      <p className="text-sm capitalize text-start">
                        {question.difficulty}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {isCreateModalOpen && (
          <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal}>
            <CreateQuestion onClose={closeCreateModal} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AllQuestions;
