import React from "react";
import ProfileAvatar from "../Images/Avatar.png";

export default function NavUserInfo({ user, score,pseudo }) {
  const username = user?.email.split("@")[0];
  return (
    <div
      className="border-b-[1px] mb-[25px]"
      style={{
        borderImage:
          "linear-gradient(to right, rgba(144, 157, 172, 0), rgba(144, 157, 172, 1), rgba(144, 157, 172, 0)) 1",
      }}
    >
      <div className="p-[13px] px-[15px] bg-[#000000bf] rounded-[5px] mb-[25px] flex flex-col gap-[10px]">
        <div className="flex align-middle justify-start items-center gap-[10px]">
          <span className="rounded-[6px] broder-[1px] bg-[#000000a6] border-[#191B22] h-[30px] w-[30px]">
            <img
              src={user?.photoURL || ProfileAvatar}
              alt=""
              className="h-[30px] w-[30px] rounded-lg"
            />
          </span>
          <span
            className={` ${username === "admin"
              ? "text-2xl font-bold"
              : "text-[13px] font-medium"
              } text-white`}
          >
            {pseudo}
          </span>
        </div>
        {username !== "admin" && (
          <h2 className="pts-large-desktop text-left font-bold text-[12px] text-white">
            Score: {parseFloat(score).toFixed(2) || 0} Pts
          </h2>
        )}
      </div>
    </div>
  );
}
