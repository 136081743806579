import React from "react";
import EventIcon from "../Images/EventIcon.svg";

export default function EventCard({ notification }) {
  return (
    <div
      className={`flex p-[10px] gap-[12px] rounded-[9.83px] bg-[#ffffff1a]  ${
        notification.type === "reward" ? "bg-[#362222]" : ""
      }`}
    >
      <div
        className={` w-[50px] rounded-[12px] bg-[#ffffff1a] h-[37px] sm:min-w-[47px] sm:h-[47px] flex items-center justify-center`}
      >
        {notification.type === "video" ? (
          notification?.thumbnailUrl ? (
            <img src={notification.thumbnailUrl} className="w-[50px] rounded-[12px] h-[37px] sm:min-w-[47px] sm:h-[47px]"  alt="event icon" />
          ) : (
            <img src={EventIcon} alt="event icon"  />
          )
        ) : (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 4.90137V13.0264M8 4.90137H5.79018C5.46457 4.90137 5.15231 4.76967 4.92208 4.53525C4.69184 4.30083 4.5625 3.98289 4.5625 3.65137C4.5625 3.31985 4.69184 3.0019 4.92208 2.76749C5.15231 2.53306 5.46457 2.40137 5.79018 2.40137C7.50894 2.40137 8 4.90137 8 4.90137ZM8 4.90137H10.2098C10.5354 4.90137 10.8477 4.76967 11.0779 4.53525C11.3081 4.30083 11.4375 3.98289 11.4375 3.65137C11.4375 3.31985 11.3081 3.0019 11.0779 2.76749C10.8477 2.53306 10.5354 2.40137 10.2098 2.40137C8.49106 2.40137 8 4.90137 8 4.90137ZM3.625 8.02637H12.375V11.6514C12.375 12.3514 12.375 12.7015 12.2387 12.9689C12.1189 13.2041 11.9277 13.3953 11.6925 13.5151C11.4251 13.6514 11.0751 13.6514 10.375 13.6514H5.625C4.92493 13.6514 4.5749 13.6514 4.30751 13.5151C4.07231 13.3953 3.88108 13.2041 3.76124 12.9689C3.625 12.7015 3.625 12.3514 3.625 11.6514V8.02637ZM3.375 8.02637H12.625C12.9751 8.02637 13.1501 8.02637 13.2838 7.95824C13.4014 7.8983 13.4969 7.80274 13.5569 7.68512C13.625 7.55143 13.625 7.37643 13.625 7.02637V5.90137C13.625 5.55134 13.625 5.37632 13.5569 5.24262C13.4969 5.12502 13.4014 5.02941 13.2838 4.96949C13.1501 4.90137 12.9751 4.90137 12.625 4.90137H3.375C3.02497 4.90137 2.84995 4.90137 2.71626 4.96949C2.59866 5.02941 2.50304 5.12502 2.44312 5.24262C2.375 5.37632 2.375 5.55134 2.375 5.90137V7.02637C2.375 7.37643 2.375 7.55143 2.44312 7.68512C2.50304 7.80274 2.59866 7.8983 2.71626 7.95824C2.84995 8.02637 3.02497 8.02637 3.375 8.02637Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#ffff"
              />
            </svg>
          </span>
        )}
      </div>

      <div className="w-[95%] flex flex-col ">
        <div className="flex justify-between items-start">
          <h3 className="text-left text-[12px] md:text-[16px] font-semibold">
            {notification?.tag}
          </h3>
          <h3 className="text-[11px] md:text-[15px] font-semibold text-right">
            {notification.timestamp?.toDate().toLocaleDateString()}
          </h3>
        </div>

        <div className="w-full ">
          <h2 className="hidden md:block text-left text-[13px] text-[#9AA0A6] truncate-1-line">
            {`Titre: ${notification?.title || notification.Titre}`}
          </h2>
          {notification.type === "video" && (
            <>
              {" "}
              <h2 className="hidden md:block text-left text-[13px] text-[#9AA0A6] truncate-1-line">
                {`Section: ${notification?.section}`}
              </h2>
              <h2 className="hidden md:block text-left text-[13px] text-[#9AA0A6] truncate-1-line">
                {`Module: ${notification?.module}`}
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
