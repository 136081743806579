import React, { useEffect, useState } from 'react';

const NextLessonModal = ({ 
  isOpen, 
  onClose, 
  nextItem,
  countdownDuration = 5 
}) => {
  const [countdown, setCountdown] = useState(countdownDuration);
  
  useEffect(() => {
    let timer;
    
    if (isOpen) {
      setCountdown(countdownDuration);
      
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            onClose(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isOpen, countdownDuration, onClose]);

  if (!isOpen || !nextItem) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div 
        className="absolute inset-0 bg-black/50" 
        onClick={() => onClose(false)}
      />
      
      {/* Modal */}
      <div className="relative bg-[#1a1f25] rounded-lg p-6 w-[90%] max-w-lg mx-auto">
        <div className="flex flex-col items-center space-y-4">
          <h2 className="text-xl font-semibold text-gray-200">
            Prochaine vidéo
          </h2>
          
          <h3 className="text-lg text-gray-200 text-center">
            {nextItem.title}
          </h3>
          
          <p className="text-sm text-gray-400">
            Commencera dans {countdown} {countdown > 1 ? "secondes":"seconde"}
          </p>

          <button
            onClick={() => onClose(true)}
            className="w-full bg-blue-600 hover:bg-blue-700 text-gray-200 font-medium py-2 px-4 rounded"
          >
            Continuer
          </button>
          
          <button
            onClick={() => onClose(false)}
            className="w-full bg-transparent hover:bg-gray-100 hover:text-black dark:hover:bg-gray-700 text-gray-400 font-medium py-2 px-4 rounded"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default NextLessonModal;