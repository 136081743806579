import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { SvgIcon } from "./SvgIcon";

import { resultCriteria } from "../data/resultCriteria";

ChartJS.register(ArcElement, Tooltip, Legend);

const QuizResultCard = ({
  score,
  totalQuestions,
  timeTaken,
  minRequiredScore,
  setIsFinished,
  handleSaveProgress,
  setShowAnswer,
  setAnswers,
  setCurrentQuizIndex,
  quizType,
  handleStartQuiz,
  totalMarks
}) => {
  let resultMessage, description, buttonText, buttonColor, chartColor;

  if (score === totalQuestions) {
    resultMessage = "Impressionnant !";
    description =
      "Félicitations ! Vous avez réalisé un sans faute ! Excellent travail !";
    buttonText = "Continuer la formation";
    buttonColor = "bg-[#32BD9C]";
    chartColor = "#32BD9C";
  } else if (score >= minRequiredScore) {
    resultMessage = "Bravo ! Tu as validé le quizz !";
    description =
      "Félicitations ! Vous avez presque atteint la perfection ! Excellent travail !";
    buttonText = "Continuer la formation";
    buttonColor = "bg-[#32BD9C]";
    chartColor = "#32BD9C";
  } else if (
    score >= resultCriteria[`${quizType}`].gradeC &&
    score < minRequiredScore
  ) {
    resultMessage = "Vous y êtes presque !";
    description =
      "Vous n'avez pas obtenu le score nécessaire. Cependant, vous êtes prêt du but ! Recommencez et tentez de nouveau votre chance !";
    buttonText = "Recommencer";
    buttonColor = "bg-[#FFA500]";
    chartColor = "#FFA500";
  } else {
    resultMessage = "L'erreur est humaine";
    description =
      "Vous n'avez pas obtenu le score nécessaire. Ne vous découragez pas; vous êtes dans une phase de votre apprentissage ! Recommencez et tentez de nouveau votre chance !";
    buttonText = "Recommencer";
    buttonColor = "bg-[#EC6767]";
    chartColor = "#EC6767";
  }

  const chartData = {
    datasets: [
      {
        data: [score, +totalMarks - score],
        backgroundColor: [chartColor, "#242649"],
        borderWidth: 0,
        borderRadius: 10,
        cutout: "92%",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div
      className={`text-white rounded-xl justify-start flex flex-col w-[640px] p-[15px] gap-6 bg-[#293057] border border-[${chartColor}]`}
    >
      <div className="bg-[#4f5471] rounded-lg p-4 flex items-center gap-2 md:gap-5">
        <div className="flex  gap-1 md:gap-2 ">
          <div className="bg-white rounded w-[30px] p-1 h-[30px] flex items-center justify-center">
            <SvgIcon name={"clock"} />
          </div>
          <span>{timeTaken}</span>
        </div>
        <div className="h-[90px] bg-white w-[2px]"></div>
        <div className="flex flex-col items-start">
          <div className="text-[12px] font-medium lg:text-xl md:font-bold">
            Temps passé
          </div>
          <div className="text-[10px] font-medium lg:font-normal lg:text-[16px] text-[#7B7FA0] text-start">
            Votre score final tient compte de vos réponses et du temps écoulé :
            plus vous avez été rapide, meilleur est votre score
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center lg:flex lg:flex-row lg:justify-start content-start lg:items-start self-start gap-4 ">
        <div className="w-[135px] h-[135px] lg:w-[200px] lg:h-48 relative">
          <Doughnut data={chartData} options={chartOptions} />
          <div className="absolute inset-0 flex items-center justify-center">
            <span className={`${chartColor}`}>
              <span className="text-4xl">{score}</span> /{""} {totalMarks}
            </span>
          </div>
        </div>
        <div className="text-start">
          <h2 className="text-[18px] md:text-xl lg:text-2xl font-bold mb-2">
            {resultMessage}
          </h2>
          <p className="text-[#7B7FA0] text-[12px] lg:text-[16px]">
            {description}
          </p>
        </div>
      </div>

      <div className="mt-4">
        {score >= minRequiredScore ? (
        
            <button
              className={`w-full lg:w-[150px] py-3 ${buttonColor} text-white rounded-lg mb-4`}
              onClick={() => handleSaveProgress()}
            >
              {buttonText}
            </button>
        ) : (
          <button
            className={`w-full lg:w-[150px] py-3 ${buttonColor} text-white rounded-lg mb-4`}
            onClick={() => {
              setIsFinished(false);
              setCurrentQuizIndex(0);
              setAnswers([]);
              handleStartQuiz()
              setShowAnswer(false);
            }}
          >
            {buttonText}
          </button>
        )}

        {buttonText === "Recommencer" && (
          <div className="text-center text-[#7B7FA0]">
            Score minimum requis : {minRequiredScore}/{+totalMarks}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizResultCard;
