import { Check, ArrowLeft } from "lucide-react";


const StepIndicator = ({ currentStep, step, label, width }) => (
  <div className="flex flex-col items-center justify-center mb-5 w-fit">
    <div
      className={`w-7 h-7 rounded-full flex items-center justify-center border border-white  ${
        currentStep >= step ? "bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]" : "bg-gray-700"
      }`}
    >
      {currentStep > step ? (
        <Check className="w-5 h-5 text-white" />
      ) : (
        <span className="text-white text-sm">{step}</span>
      )}
    </div>
    <span
      className={`top-8  right-3 md:block mt-1 text-xs w-[68px] ${
        currentStep >= step ? "text-white" : "text-gray-500"
      }`}
    >
      {label}
    </span>
  </div>
);

export const Steps = ({ currentStep }) => (
  <div className="flex items-start justify-center w-full px-6 mb-0">
    <StepIndicator currentStep={currentStep} step={1} label="Information" />
    <div
      className={`h-[5px] flex-1 mt-3 mx-2 border border-gray-600 rounded-lg ${
        currentStep >= 2 ? "bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]" : "bg-gray-700"
      }`}
    />
    <StepIndicator currentStep={currentStep} step={2} label="Paiement" />
    <div
      className={`h-[5px] flex-1 mt-3 mx-2 border border-gray-600 rounded-lg ${
        currentStep >= 3 ? "bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]" : "bg-gray-700"
      }`}
    />
    <StepIndicator
      currentStep={currentStep}
      step={3}
      label={`Activation du compte`}
    />
  </div>
);