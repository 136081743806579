import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { auth } from "../firebase/config";
import { RxCross2 } from "react-icons/rx";

const Securite = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm();

  const [currentError, setCurrentError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleValidation = () => {
    const values = getValues();
    clearErrors();
    setCurrentError("");
    setSuccessMessage("");

    if (!values.currentPassword) {
      setError("currentPassword", {
        type: "required",
        message: "Le mot de passe actuel est requis",
      });
      setCurrentError("Le mot de passe actuel est requis");
      return false;
    }

    if (!values.newPassword) {
      setError("newPassword", {
        type: "required",
        message: "Un nouveau mot de passe est requis",
      });
      setCurrentError("Un nouveau mot de passe est requis");
      return false;
    }

    if (values.newPassword !== values.confirmPassword) {
      setError("confirmPassword", {
        type: "validate",
        message: "Les mots de passe ne correspondent pas.",
      });
      setCurrentError("Les mots de passe ne correspondent pas.");
      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    const user = auth.currentUser;
    const { currentPassword, newPassword } = data;

    // Re-authenticate the user
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    reauthenticateWithCredential(user, credential)
      .then(() => {
        // If re-authentication is successful, update the password
        return updatePassword(user, newPassword);
      })
      .then(() => {
        setSuccessMessage("Le mot de passe a été mis à jour avec succès");
      })
      .catch((error) => {
        setCurrentError(
          "Erreur lors de la mise à jour du mot de passe : " +
            "L'e-mail ou le mot de passe est erroné"
        );
      });
  };

  return (
    <div className="">
      <div className="flex justify-start flex-col">
        <div className="mb-[60px]">
          {currentError && (
            <div className="flex h-[60px] p-4 px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-[fit-content] mt-4">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1">
                    <g id="Icon-Set-Filled">
                      <path
                        id="cross-circle"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                        fill="#CE0500"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div>{currentError}</div>
            </div>
          )}
          {successMessage && (
            <div className="flex h-[60px] p-4 px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#E9FFE9] w-[250px] sm:w-[fit-content] mt-4">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1">
                    <g id="Icon-Set-Filled">
                      <path
                        id="check-circle"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0ZM14.7162 7.55627C15.0537 7.21877 15.0537 6.68127 14.7162 6.34377C14.3787 6.00627 13.8412 6.00627 13.5037 6.34377L8.75 11.0975L6.49625 8.84377C6.15875 8.50627 5.62125 8.50627 5.28375 8.84377C4.94625 9.18127 4.94625 9.71877 5.28375 10.0563L8.19625 12.9688C8.37125 13.1438 8.60375 13.2413 8.8425 13.2413C9.08125 13.2413 9.31375 13.1438 9.48875 12.9688L14.7162 7.55627Z"
                        fill="#00CE00"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div>{successMessage}</div>
            </div>
          )}
        </div>
        <span className="text-white font-normal leading-normal text-[16px] flex justify-start pb-[22px]">
          Changer de mot de passe
        </span>

        <form
          onSubmit={handleSubmit((data) => {
            if (handleValidation()) {
              onSubmit(data);
            }
          })}
          className="w-full"
        >
          <div className="flex justify-start items-start flex-col gap-[22px] ">
            <div className="flex justify-start items-start flex-col gap-2 w-full">
              <div>
                <span className="text-white font-normal text-[13px] md:text-[16px] flex justify-start">
                 Mot de passe actuel
                </span>
              </div>
              <div className="w-full md:w-[417px] relative">
                <input
                  type="password"
                  id="currentPassword"
                  placeholder="*********"
                  {...register("currentPassword")}
                  style={{ backgroundColor: "transparent" }}
                  className="bg-transparent py-[9px] px-4 md:py-4 md:px-6 rounded-[15px] w-full border-[1px] border-[#ffffff1a] text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                />
                {errors.currentPassword && (
                  <RxCross2 className="bg-red-500 w-6 h-6 rounded-full absolute right-3 transform -translate-y-1/2 top-1/2" />
                )}
              </div>
            </div>

            <div className="flex justify-start items-start flex-col gap-2 w-full ">
              <span className="text-white font-normal leading-normal text-[13px] md:text-[16px]">
                Nouveau mot de passe
              </span>
              <div className="w-full md:w-[417px] relative">
                <input
                  type="password"
                  id="newPassword"
                  {...register("newPassword")}
                  placeholder="*****************"
                  className="bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                />
                {errors.newPassword && (
                  <RxCross2 className="bg-red-500 rounded-full h-6 w-6 absolute right-3 top-1/2 transform -translate-y-1/2" />
                )}
              </div>
            </div>

            <div className="flex justify-start items-start flex-col gap-2 w-full">
              <span className="text-white font-normal leading-normal text-[13px] md:text-[16px]">
                Comfirmer nouveau mot de passe
              </span>
              <div className="w-full md:w-[417px] relative">
                <input
                  id="confirmPassword"
                  {...register("confirmPassword")}
                  type="password"
                  placeholder="*****************"
                  className="bg-transparent py-[9px] px-4 md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                />
                {errors.confirmPassword && (
                  <RxCross2 className="bg-red-600 h-6 w-6 rounded-full absolute right-3 transform -translate-y-1/2 top-1/2" />
                )}
              </div>
            </div>
          </div>
          <div className=" mt-4 w-full md:w-[417px]">
            <button
              type="submit"
              className="sm:w-auto font-light flex h-12 py-4 px-5 justify-center items-center gap-2.5 rounded-[15px] bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1] text-white text-base  md:font-medium text-center text-[10px] md:text-[16px]"
            >
              Confirmer les changements
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Securite;
