// HorizontalNav.js
import React, { useState } from "react";
import ProfileModify from "./ProfileModify";
import Preferences from "./Preferences";
import Securite from "./Securite";

const navItems = ["Modifier le profil", "Préférences", "Sécurité"];

const ProfileTemplate = () => (
  <div className="">
    <ProfileModify />
  </div>
);

const PreferencesTemplate = () => (
  <div className="">
   
    <div>
    <Preferences/>

    </div>
  </div>
);

const SecurityTemplate = () => (
  <div className="">
    <Securite />
  </div>
);

export default function SettingsNavigation() {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderTemplate = () => {
    switch (activeIndex) {
      case 0:
        return <ProfileTemplate />;
      case 1:
      return <PreferencesTemplate />;
      case 2:
        return <SecurityTemplate />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-start gap-2 sm:gap-[36px] w-full border-b-[1px] border-[#ffffff1a]">
        {navItems.map((item, index) => (
          
            <div
            key={index}
            className={`text-white text-[12px]  md:text-[16px] p-[5px] md:p-[10px] cursor-pointer relative 
        after:content-[''] after:absolute after:w-0 after:-bottom-[1.5px] after:left-0 
        after:h-[2px] after:rounded-[20px] after:bg-[#0075FF]  md:after:bg-[#fff] after:transition-[width] after:duration-500 
        after:ease-in-out ${
          activeIndex === index ? "text-blue-600 lg:text-white after:w-full after:bg-[#0075FF] lg:after:bg-white " : ""
        }`}
            onClick={() => setActiveIndex(index)}
          >
            {item}
          </div>
         
        ))}
      </div>

      <div className="content">{renderTemplate()}</div>
    </div>
  );
}
