//common components
import Header from '../Components/Header'

//Page components
import SettingsNavigation from '../SettingsPageComponents/SettingsNavigation'


//Random Compoenents
export default function Settings({ setIsSidebarOpen, isSidebarOpen }) {
  return (
    <div className=''>
      {/* Header */}
      <div className="flex flex-col">
        <Header PageName={"Paramètres"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>

      {/* Content */}
      <div className='bg-bgColor  w-[100%] pt-[22px] pr-[22px] pb-[60px] pl-[22px] gap-[60px] rounded-[15px] border-[1px] border-borderColor'>
        <div className="">
          <SettingsNavigation />
        </div>

      </div>

    </div>
  )
}
