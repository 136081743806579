import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase/config";
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";

const GlobalContext = createContext(null);

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [openModuleId, setOpenModuleId] = useState(null);
  const [openSectionId, setOpenSectionId] = useState(null);
  const [userProgress, setUserProgress] = useState({});
  const [currentItem, setCurrentItem] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [sectionCompletionStatus, setSectionCompletionStatus] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user?.uid) {
        const userProgressRef = doc(db, "userProgress", user?.uid);
        onSnapshot(userProgressRef, (snapshot) => {
          setUserProgress({ id: snapshot?.id, ...snapshot?.data() });
          fetchData({ id: snapshot.id, ...snapshot.data() });
        });
      }
    });
  }, []);

  const fetchCollection = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const fetchData = async (userProgress) => {
    try {
      const [sections, modules, videos, quizzes] = await Promise.all([
        fetchCollection("sections"),
        fetchCollection("modules"),
        fetchCollection("videos"),
        fetchCollection("quizes"),
      ]);

      const formattedData = sections
        .filter((section) => section?.status !== "inactive")
        .map((section) => ({
          ...section,
          modules: modules
            .filter(
              (module) =>
                module.section?.sectionId === section.id &&
                module.status !== "inactive"
            )
            .map((module) => ({
              ...module,
              content: [
                ...videos
                  .filter(
                    (video) =>
                      video.module?.moduleId === module.id &&
                      video?.status !== "inactive"
                  )
                  .map((video) =>
                    userProgress?.completedItems?.includes(video?.id)
                      ? { ...video, status: "completed" }
                      : userProgress?.currentItem?.id === video.id
                      ? { ...video, status: "unlocked" }
                      : { ...video }
                  ),
                ...quizzes
                  .filter(
                    (quiz) =>
                      quiz.module?.moduleId === module.id &&
                      quiz?.status !== "inactive"
                  )
                  .map((quiz) =>
                    userProgress?.completedItems?.includes(quiz.id)
                      ? { ...quiz, status: "completed" }
                      : userProgress?.currentItem?.id === quiz.id
                      ? { ...quiz, status: "unlocked" }
                      : { ...quiz }
                  ),
              ].sort((a, b) => (a.order || 0) - (b.order || 0)),
            }))
            .sort((a, b) => (a.order || 0) - (b.order || 0)),
        }))
        .sort((a, b) => (a.order || 0) - (b.order || 0));

        // console.log("formatted course data...", formattedData)
      setCourseData(formattedData);
      calculateSectionCompletionStatus(formattedData);
      setError(null);
    } catch (err) {
      // console.error("Error fetching data:", err);
      setError("An error occurred while fetching data. Please try again.");
    }
  };

  const isModuleCompleted = (module) => {
    // console.log("module received for completeion satus check", module);
    return module?.content.every((item) =>
      userProgress?.completedItems?.includes(item.id)
    );
  };

  const isSectionCompleted = (section) => {
    // console.log("section received for completeion satus check", section);
    return section?.modules.every(isModuleCompleted);
  };

  const unlockNextModule = async (currentModule, currentSection) => {
    // console.log("current Module and current Section recived for unlock", currentModule?.id , currentSection?.id)
    const moduleIndex = currentSection.modules.findIndex(
      (m) => m.id === currentModule.id
    );
    const nextModule = currentSection.modules[moduleIndex + 1];
      // console.log(nextModule, "Next module found")
    if (nextModule) {
       return nextModule
    } else {
      return null;
    }

  };

  const unlockNextSection = (currentSection) => {
    const sectionIndex = courseData.findIndex(
      (s) => s.id === currentSection.id
    );
    const nextSection = courseData[sectionIndex + 1];

    if (nextSection) {
      return nextSection
    } else {
      return null;
    }

  };
  const findNextItem = (currentItem) => {
    const currentSection = courseData.find((section) =>
      section.modules.some((module) =>
        module.content.some((item) => item.id === currentItem.id)
      )
    );

    if (!currentSection) return null;

    const currentModule = currentSection.modules.find((module) =>
      module.content.some((item) => item.id === currentItem.id)
    );

    if (!currentModule) return null;

    // Find next item in the same module
    const currentItemIndex = currentModule.content.findIndex(
      (item) => item.id === currentItem.id
    );
    if (currentItemIndex < currentModule.content.length - 1) {
      return currentModule.content[currentItemIndex + 1];
    }

    // Find next module in the same section
    const nextModule = currentSection.modules
      .filter((module) => module.order > currentModule.order)
      .sort((a, b) => a.order - b.order)[0];

    if (nextModule) {
      return nextModule.content.sort((a, b) => a.order - b.order)[0];
    }

    // Find next section
    const nextSection = courseData
      .filter((section) => section.order > currentSection.order && section.status !== "inprogress")
      .sort((a, b) => a.order - b.order)[0];

    if (nextSection) {
      const firstModule = nextSection.modules.sort(
        (a, b) => a.order - b.order
      )[0];
      return firstModule.content.sort((a, b) => a.order - b.order)[0];
    }

    // If no next item found, return null (end of course)
    return null;
  };
 

  const handleItemCompletion = async (currentItem, sectionId, moduleId) => {
    // console.log("sectionId", sectionId);
    // console.log("moduleId", moduleId);

    const userProgressRef = doc(db, "userProgress", userProgress?.id);

    await updateDoc(userProgressRef, {
      completedItems: arrayUnion(currentItem?.id),
    });

    const currentSection = courseData?.find((s) => s.id === sectionId);
    const currentModule = currentSection?.modules.find((m) => m.id === moduleId);

    // console.log("current Section", currentSection);
    // console.log("current Module", currentModule);

    // Check for module completion
    if (isModuleCompleted(currentModule)) {
      console.log(currentModule?.id, "is Completed")
      await updateDoc(userProgressRef, {
        completedItems: arrayUnion(currentModule?.id),
        completedModules: arrayUnion(currentModule?.id),
      });

      const nextModule = await unlockNextModule(currentModule, currentSection);
      // console.log("nextModule returned", nextModule)

      if (nextModule) {
        await updateDoc(userProgressRef, {
          currentModule: {
            title: nextModule?.title,
            id: nextModule?.id,
            order: nextModule?.order,
            moduleSection: nextModule?.section,
            status: "unlocked",
          },
        });

      }
    }

    if (isSectionCompleted(currentSection)) {
      // console.log(currentSection, "is Completed")
      await updateDoc(userProgressRef, {
        completedItems: arrayUnion(currentSection.id),
        completedSections: arrayUnion(currentSection.id),
      });

      const nextSection = await unlockNextSection(currentSection);
      if (nextSection) {
        // console.log(nextSection, "next section")
        await updateDoc(userProgressRef, {
          currentSection: {
            title: nextSection?.title,
            order: nextSection?.order,
            id: nextSection?.id,
            status: "unlocked",
          },
        });
      }
    }
    let nextItem = findNextItem(currentItem);

    if (nextItem) {
      await updateDoc(userProgressRef, {
        currentItem: { ...nextItem, status: "unlock" },
      });
    
      setCurrentItem({ ...nextItem, status: "unlocked" });
      return nextItem;
    } else {
      toast.success("Course completed!");
      return null;
    }
  };
  const calculateSectionCompletionStatus = (data) => {
    const completionStatus = data.map((section) => {
      const totalItems = section.modules.reduce((total, module) => {
        return total + module.content.length;
      }, 0);

      const completedItems = section.modules.reduce((completed, module) => {
        return (
          completed +
          module.content.filter((item) => item.status === "completed").length
        );
      }, 0);

      const completionPercentage =
        totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;

      return {
        sectionId: section.id,
        sectionName: section.title,
        sectionDescription: section.description,
        completionPercentage,
      };
    });

    setSectionCompletionStatus(completionStatus);
  };

  return (
    <GlobalContext.Provider
      value={{
        courseData,
        setOpenSectionId,
        openSectionId,
        openModuleId,
        setOpenModuleId,
        setUserProgress,
        userProgress,
        currentItem,
        setCurrentItem,
        sectionCompletionStatus,
        handleItemCompletion,
        findNextItem,
        error
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};