import React, { useState, useEffect } from 'react';
import { useFirebase } from '../context/firebaseContext';
import useUserRole from '../hooks/useUserRole';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import toast from 'react-hot-toast';

const ModuleForm = ({ module, onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    order: null,
    type: "module",
    section: { title: '', sectionId: '' },
    status: 'inactive'
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [availableSections, setAvailableSections] = useState([]);
  const { updateModule, addModule } = useFirebase();
  const { user } = useUserRole();

  const fetchSections = async () => {
    try {
      const sectionsCollection = await getDocs(collection(db, 'sections'));
      const sectionsData = sectionsCollection.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title
      }));
      setAvailableSections(sectionsData);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  useEffect(() => {
    fetchSections();
    if (module) {
      setFormData({
        title: module.title || '',
        description: module.description || '',
        order: +module.order || '',
        type: module.type,
        section: module.section || { title: '', sectionId: '' },
        status: module.status || 'locked'
      });
    }
  }, [module]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handleSectionChange = (e) => {
    const selectedSection = availableSections.find(section => section.id === e.target.value);
    setFormData(prevData => ({
      ...prevData,
      section: {
        title: selectedSection ? selectedSection.title : '',
        sectionId: selectedSection ? selectedSection.id : ''
      }
    }));
    if (errors.section) {
      setErrors(prevErrors => ({ ...prevErrors, section: '' }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!formData.order) newErrors.order = 'Order is required';
    if (!formData.section.sectionId) newErrors.section = 'Section is required';
    if (!formData.status) newErrors.status = 'Status is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const moduleData = {
          title: formData.title,
          description: formData.description,
          order: +formData.order,
          section: formData.section,
          type: formData.type,
          status: formData.status,
          createdBy: user?.email.split('@')[0]
        };

        if (module) {
          const res = await updateModule(module.id, moduleData);
          res.status === "success" ? toast.success(res.message, {
            position: 'top-center'
          }) : toast.error(res.message, {
            position: 'top-center'
          })
        } else {
          const res = await addModule(moduleData);
          res.status === "success" ? toast.success(res.message, {
            position: 'top-center'
          }) : toast.error(res.message, {
            position: 'top-center'
          })
        }
        onClose();
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error(error, {
          position: 'top-center'
        })

      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border text-white">
      <h2 className="text-xl text-start font-medium py-4 border-b mb-6">
        {module ? 'Update Module' : 'Add Module'}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="block text-sm font-medium mb-1">Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.title && <p className="mt-1 text-sm text-red-500">{errors.title}</p>}
      </div>

      <div className="mb-4 flex-col items-start">
        <label htmlFor="order" className="block text-start text-sm font-medium mb-1">Order</label>
        <input
          type="number"
          id="order"
          name="order"
          value={formData.order}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.order && <p className="mt-1 text-sm text-red-500">{errors.order}</p>}
      </div>

      <div className="mb-4 flex-col items-start">
        <label htmlFor="section" className="block text-start text-sm font-medium mb-1">Section</label>
        <select
          id="section"
          name="section"
          value={formData.section.sectionId}
          onChange={handleSectionChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a section</option>
          {availableSections.map(section => (
            <option key={section.id} value={section.id}>{section.title}</option>
          ))}
        </select>
        {errors.section && <p className="mt-1 text-sm text-red-500">{errors.section}</p>}
      </div>

      <div className="mb-4 flex-col items-start">
        <label htmlFor="status" className="block text-start text-sm font-medium mb-1">Status</label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="locked">Locked</option>
          <option value="unlocked">Unlocked</option>
          <option value="inactive">Inactive</option>
          <option value="completed" disabled className='text-gray-600'>Completed</option>
        </select>
        {errors.status && <p className="mt-1 text-sm text-red-500">{errors.status}</p>}
      </div>

      <div className="mb-4 flex-col items-start">
        <label htmlFor="description" className="block text-start text-sm font-medium mb-1">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="3"
        ></textarea>
        {errors.description && <p className="mt-1 text-sm text-red-500">{errors.description}</p>}
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 bg-primaryBlue text-white rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        >
          {isLoading ? 'Processing...' : (module ? 'Update Module' : 'Add Module')}
        </button>
      </div>
    </form>
  );
};

export default ModuleForm;
