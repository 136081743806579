import React from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../Components/SvgIcon";
import QuizImage from "../Images/QuizImage.png";

const Spinner = () => (
  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

export default function QuizSummary({ quiz, handleStartQuiz, isLoading }) {
  return (
    <div className="w-[100%]">
      <div className="lg:flex w-full overflow-y-auto scrollbar-thin justify-start bg-[#080e2d] rounded-2xl border-1 border border-[#4F5572]">
        <div className="flex flex-col gap-2 relative">
          <div className="flex justify-center">
            <div>
              <h2 className="font-medium text-center text-[15px] lg:text-[18px] text-white pt-4">
                Quiz 1 - {`${quiz?.title}`}
              </h2>
            </div>
          </div>
          
          <div className="xl:p-[27.5px_107px]">
            <div className="quiz-intro-inner-wrapper rounded-lg items-start px-5 p-4 flex flex-col gap-2 mt-4 w-full xl:bg-[#252B52]">
              <img src={quiz?.thumbnailUrl || QuizImage} alt="" className="flex w-full" />
              <div className="bg-[#484e58] rounded-lg p-2 grid grid-cols-1 lg:grid-cols-12 gap-2 lg:gap-4 divide-y lg:divide-y-0 divide-[#ffffff3f] lg:divide-x w-full mt-5">
                <div className="flex items-center gap-2 lg:col-span-2">
                  <div className="w-6 h-6 rounded-lg bg-white p-1 flex justify-center items-center">
                    <SvgIcon name="clock" />
                  </div>
                  <span className="text-white text-sm font-medium flex">
                    {quiz?.duration} Minutes
                  </span>
                </div>

                <div className="flex flex-col gap-1 lg:col-span-8 lg:pl-4 pt-2 lg:pt-0">
                  <h4 className="text-start text-sm font-medium text-white">
                    Temps moyen estimé
                  </h4>
                  <p className="text-start text-xs font-normal text-[#A0AEC0]">
                    Le chronomètre démarre dès que la première question
                    apparaît. Bonne chance !
                  </p>
                </div>
              </div>
              <div>
                <h2 className="text-start text-[16px] font-medium lg:text-xl text-white mt-4">
                  Quiz 1 - {quiz?.title}
                </h2>
                <div className="text-left text-[12px] lg:text-[13px] xl:text-[15px] text-[#A0AEC0]" />
                <h3
                  className="text-start text-[#A0AEC0] text-[12px] font-normal lg:text-[16]"
                  dangerouslySetInnerHTML={{ __html: quiz?.description }}
                ></h3>
              </div>

              <div className="w-full mt-4">
                <button
                  className="primary-btn w-full lg:w-[188px] flex p-[10px] justify-center items-center rounded-[7px] bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1] text-white font-bold text-[12px] uppercase"
                  onClick={() => handleStartQuiz()}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner />
                      <span className="ml-2">Chargement...</span>
                    </>
                  ) : (
                    "Commencer le quiz"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}