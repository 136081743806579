import React from "react";
import { SvgIcon } from "../Components/SvgIcon";

export default function RewardLocked({ lockedReason, title, status, url }) {

  const handleAttachFileDownload = async (filesUrl) => {
    try {
      if (!filesUrl) return;

      const downloadPromises = [];

      filesUrl.forEach((fileUrl) => {
        const filename = fileUrl.split("/").pop();
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", filename);
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");

        downloadPromises.push(
          new Promise((resolve) => {
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(resolve, 100);
          })
        );
      });

      await Promise.all(downloadPromises);
    } catch (error) {
      console.error("Error downloading attached file:", error);
    }
  };
  return (
    <div
      className={`flex lg:flex-col items-center lg:h-[200px] gap-[20px] lg:px-10 py-2 px-6 lg:py-8 rounded-[9.83px] ${
        status === "locked" ? "bg-[#ffffff1a] h-[94px] sm:h-[66px]" : "bg-bgColor h-[123px] sm:h-[83px]"
      }`}
    >
      <div
        className={`flex  lg:justify-center lg:items-center w-fit lg:w-full ${
          status === "unlocked" ? "mt-8 cursor-pointer" : ""
        }`}
      >
        {status === "locked" ? (
          <button className="w-fit">
            <SvgIcon name={"rewardLock"} />
          </button>
        ) : (
          <button className="w-fit" onClick={() => handleAttachFileDownload([url])}>
            <SvgIcon name={"download"} />
          </button>
        )}
      </div>

      <div className="flex flex-col lg:justify-center lg:items-center w-full">
        {status === "locked" ? (
            <h3
              className={`leading-[150%] text-left lg:text-center ${
                status === "locked"
                  ? "font-medium text-[14px]   text-[#A0AEC0]"
                  : "font-semibold text-[16px]   text-white"
              }`}
            >
              Tu dois terminer "{lockedReason?.title}" pour la débloquer..
             </h3>
        ) : (
          <>
            <h3
              className={`leading-[150%] text-left lg:text-center ${
                status === "locked"
                  ? "font-medium text-[14px] text-[#A0AEC0]"
                  : "font-semibold text-[16px]  text-white"
              }`}
            >
              {title}
            </h3>
            <span className="details text-left lg:text-center text-white text-xs">
              Cliquez ici pour télécharger votre récompense
            </span>
          </>
        )}
      </div>
    </div>
  );
}
