import React from 'react'

export default function Tag({text}) {
    const tagStyle = {
        color: "white",
        border: `1px solid #b59d19`,

    };
  return (
    <div className='flex rounded-[45px] py-[3px] px-[10px] border-[1px] border-[#cbcabc] w-[fit-content] bg-[#ffffff1a]' style={tagStyle}>
      <h4 className='text-[10px] font-bold'>{text}</h4>
    </div>
  )
}
