import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { auth } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";
import SubmitButton from "../Components/SubmitButton";
import LoginBg from "../Images/LoginBg.png";
import LogoImage from "../Images/LogoWithText.png";
import { Check, ChevronDown } from "lucide-react";
import SuccessMark from "../Images/payment-success.png";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm();

  const [currentError, setCurrentError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleValidation = () => {
    const values = getValues();
    clearErrors();
    setCurrentError("");

    if (!values.email) {
      setError("email", { type: "required", message: "L'e-mail est requis" });
      setCurrentError("L'e-mail est requis");
      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    const { email } = data;
    try {
      await sendPasswordResetEmail(auth, email);

      toast.success("Lien de réinitialisation envoyé par e-mail", {
        position: "top-center",
      });
      setIsSubmitted(true);
    } catch (error) {
      setCurrentError(error.message);
    }
  };

  return (
    <div
      className="flex flex-col items-center min-h-screen bg-gradient-to-br from-blue-900 to-black"
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <div className="mb-10 flex items-center gap-2 mt-6 md:mt-4">
      <img src={LogoImage} width={180} height={130} alt="Logo" />
      </div>
      <div className="flex items-center justify-center w-full h-full md:mt-4">
        <main className={`w-[90%] md:max-w-[600px] md:py-10 p-6 rounded-lg bg-[#121937] border border-gray-800 flex flex-col gap-8 md:gap-12 h-fit ${isSubmitted? "h-fit p-16": "md:h-[560px]"}`}>
          <div className="text-start text-gray-500">
            <Link to={"/login"} className="flex items-center">
              <ChevronDown className="rotate-90" />
              Retour
            </Link>
          </div>
          {currentError && (
            <div className="flex h-[60px] p-4 mx-auto px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-fit absolute -top-18 left-[50%] -translate-x-[50%]">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1">
                    <g id="Icon-Set-Filled">
                      <path
                        id="cross-circle"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                        fill="#CE0500"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div>{currentError}</div>
            </div>
          )}

          {isSubmitted ? (
            <div className="text-center text-white h-fit">
              <div className="bg-green-500 w-fit mx-auto mb-4 rounded-full p-2">
                <Check size={40} color="black" />
              </div>
              <p className="font-bold text-4xl">Le lien a été envoyé !</p>
              <div className="py-2 text-gray-500">
                <p className="text-gray-500">L'e-mail a bien été envoyé.</p>
                <p className="text-gray-500">
                  Tu y trouveras un lien pour réinitialiser ton mot de passe.
                </p>
              </div>
              <Link
                to="/login"
                className="text-[#113BE1]  underline cursor-pointer"
              >
                <div
                  className={`flex  py-[10px] px-5 rounded-md justify-center items-center gap-2.5 bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] text-white text-base font-medium text-center mt-7 text-[0.890rem] sm:text-[1rem]`}
                >
                  Connexion
                </div>
              </Link>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-8 py-4 px-0 ">
              <div className="flex flex-col w-full lg:px-12">
                <header className="flex flex-col gap-2">
                  <h2 className="text-xl md:text-3xl font-bold text-white">
                    Réinitailise ton mot de passe
                  </h2>
                  <p className="text-[16px] md:text-[20px]  text-gray-500">
                    Saisis ton adresse e-mail pour recevoir un lien de
                    réinitialisation.
                  </p>
                </header>
                <form
                  onSubmit={handleSubmit((data) => {
                    if (handleValidation()) {
                      onSubmit(data);
                    }
                  })}
                  className="flex flex-col w-full mt-8 md:mt-10"
                >
                  <label className="flex flex-col gap-1">
                    <span className="text-white text-sm flex justify-start">
                      Adresse mail
                    </span>
                    <input
                      type="email"
                      id="email"
                      {...register("email")}
                      onChange={() => {
                        setCurrentError("");
                        clearErrors();
                      }}
                      placeholder="Entre ton adresse mail"
                      className="w-full focus:shadow-glow px-3 py-[10px] mt-1 text-gray-200 bg-[#2a304b] border border-transparent focus:border-blue-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {errors.email && (
                      <span className="text-red-500">
                        {errors.email.message}
                      </span>
                    )}
                  </label>

                  <SubmitButton
                    buttonText="Envoyer le lien"
                    type="submit"
                    width="full"
                  />

                  <div className="text-white text-sm mt-8 md:mt-14">
                    Tu te souviens de ton mot de passe ?{" "}
                    <Link
                      to="/login"
                      className="text-[#113BE1]  underline cursor-pointer"
                    >
                      Connecte-toi
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ForgotPassword;
