import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import { TrashIcon, PencilIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import VideoForm from "../Components/VideoForm";
import toast from "react-hot-toast";

const Videos = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVideo, setSelectedVideo] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 8;

  const { deleteVideo } = useFirebase();

  useEffect(() => {
    const videoCollection = collection(db, "videos");
    const unsubscribe = onSnapshot(videoCollection, (snapshot) => {
      const videoList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideos(videoList);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const results = videos.filter(video =>
      video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.createdBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredVideos(results);
    setCurrentPage(1);
  }, [searchTerm, videos]);

  const handleAdd = () => {
    setSelectedVideo(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (video) => {
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this video?')) {
      await deleteVideo(id);
      toast.success("Video deleted successfully...", {
        position: 'top-center'
      });
    }
  };

  // Pagination
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="w-full rounded-xl h-full">
      <div className="flex flex-col">
        <Header
          PageName={"videos"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="p-6 bg-[#060B26] rounded-xl border-[#ffffff55] border">
        <div className="w-full p-6 bg-[#060B26] rounded-xl">
          <div className="flex justify-between items-center mb-4">
            <input
              type="text"
              placeholder="Search videos..."
              className="px-4 py-2 border rounded-md bg-[#1C2536] text-white"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={handleAdd}
              className="px-4 py-2 bg-primaryBlue text-white rounded-md"
            >
              Add Video
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-[#1c253600]">
                <tr>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Title</th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Converted</th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Created By</th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Order</th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Status</th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Module</th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-[#060B26] divide-y divide-gray-700">
                {currentVideos.map((video) => (
                  <tr key={video.id}>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">{video.title}</td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">{video.isConverted}</td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">{video.createdBy}</td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">{video.order}</td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">{video.status}</td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">{video.module.title}</td>
                    <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => handleUpdate(video)}
                        className="text-indigo-600 hover:text-indigo-900 mr-2"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(video.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Updated Pagination */}
          <div className="mt-6 flex flex-col items-center">
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              {[...Array(Math.ceil(filteredVideos.length / videosPerPage)).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    currentPage === number + 1
                      ? 'bg-primaryBlue text-white'
                      : 'bg-[#1C2536] text-white hover:bg-[#2C3546]'
                  }`}
                >
                  {number + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredVideos.length / videosPerPage)}
                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <VideoForm video={selectedVideo} onClose={handleCloseModal} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Videos;