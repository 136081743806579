import React from 'react'
import Tag from './Tag'
import PrimaryButton from '../Components/PrimaryButton'

export default function CourseCard({ tagText, CourseHeading, CourseDescription, buttonColor }) {

    const cardStyle = {
        border: "2px solid transparent",
        padding: "20px",
        borderRadius: "10px",
        backgroundImage: "linear-gradient(#030C2B, #030C2B), linear-gradient(to right, #E42C64, #614AD3)",
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box"
    };
    return (
        <div className='h-full flex flex-col bg-[#030C2B]  justify-between self-stretch items-start gap-10 p-[24px] rounded-[9.83px] ' style={cardStyle}>
            <div className='flex flex-col gap-6 flex-grow'>
                <Tag color={"#614AD3"} text={tagText} />

                <div className="flex flex-col w-full">
                    <h2 className='text-[16px] font-bold text-white text-left'>{CourseHeading}</h2>
                    <h4 className='text-[12px] text-[#A0AEC0] text-left'>{CourseDescription}</h4>
                </div>
            </div>
            <div className='w-full'>
                <PrimaryButton buttonText={tagText === "Niveau 1" ? "Je découvre le Guide": "Je découvre la formation"}  buttonColor = {buttonColor} />
            </div>

        </div>
    )
}
