import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import SubmitButton from "../Components/SubmitButton";
import useUserRole from "../hooks/useUserRole";
import { auth, db } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import toast from "react-hot-toast";

const Preferences = () => {
  const [isToggledNotification, setIsToggledNotification] = useState(false);
  const [isToggledNewsletter, setIsToggledNewsletter] = useState(false);
  const [currentError, setCurrentError] = useState("");
  const { user } = useUserRole();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    let unsubscribe;
    onAuthStateChanged(auth, (user) => {
      const userRef = doc(db, "users", user?.uid); // Adjust collection path accordingly
      unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data().preferences || {};
          setValue("language", data?.language || "");
          setIsToggledNotification(data?.notification || false);
          setIsToggledNewsletter(data?.newsletter || false);
        }
      });
    });

    return () => unsubscribe();
  }, []);

  const handleValidation = () => {
    const values = getValues();
    clearErrors();
    setCurrentError("");

    if (!values.language) {
      setError("language", { type: "required", message: "La langue est requise" });
      setCurrentError("La langue est requise");
      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    const userRef = doc(db, "users", user?.uid);
    try {
      await updateDoc(userRef, {
        preferences: {
          language: data.language,
          notification: isToggledNotification,
          newsletter: isToggledNewsletter,
        },
      });
      toast.success("Préférences mises à jour", {
        position: "top-center",
      });
    } catch (error) {
      toast.error(error, {
        position: "top-center",
      });
    }
  };

  const handleToggleNotification = () => {
    setIsToggledNotification(!isToggledNotification);
  };

  const handleToggleNewsletter = () => {
    setIsToggledNewsletter(!isToggledNewsletter);
  };

  return (
    <div className="w-full flex flex-col items-start">
      <div className="">
        {currentError && (
          <div className="flex h-[60px] p-4 px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-[fit-content] mt-4">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1">
                  <g id="Icon-Set-Filled">
                    <path
                      id="cross-circle"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                      fill="#CE0500"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div>{currentError}</div>
          </div>
        )}
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-start items-start md:py-8 gap-8  lg:gap-24 xl:gap-32">
        <form
          onSubmit={handleSubmit((data) => {
            if (handleValidation()) {
              onSubmit(data);
            }
          })}
          className="w-full lg:w-[964px] mt-3"
        >
          <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-12 p-1">
            <div className="flex justify-start items-start flex-col gap-2 lg:w-[417px] ">
              <div className="w-full sm:w-[417px]">
                <span className="text-white font-normal text-[13px] md:text-[16px] flex justify-start mb-1">
                  Langue
                </span>
              </div>
              <div className="w-full lg:w-[350px] flex items-start">
                <input
                  type="text"
                  id="language"
                  placeholder="Français"
                  {...register("language")}
                  style={{ backgroundColor: "transparent" }}
                  className="w-full bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a]  text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                />
              </div>
            </div>

            {/* Notification Toggles */}
            <div className="flex flex-col w-full mt-2 gap-2">
              <span className="text-white font-normal leading-normal text-[14px] flex justify-start">
                Notification
              </span>
              <div className="flex flex-col gap-2 mt-2 lg:mt-0 w-full">
                {/* Toggle for Newsletter */}
                <div className="flex flex-row items-center gap-2 w-full ">
                  <div
                    className={`${
                      isToggledNotification ? "bg-[#16DBCC]" : "bg-[#DFEAF2]"
                    }  min-w-[70px] h-[32px] cursor-pointer select-none rounded-full relative`}
                    onClick={handleToggleNotification}
                  >
                    <div
                      className={`text-sm font-bold cursor-pointer bg-white text-white px-3 py-2 rounded-full flex justify-center items-center 
                      w-[30px] h-[30px] 
                   
                      absolute transition-all duration-300 ease-in-out 
                      ${
                        isToggledNotification
                          ? "left-[38px]"
                          : "left-0 sm:left-[2px] md:left-[2px] lg:left-[3px]"
                      }`}
                    >
                      {" "}
                    </div>
                  </div>
                  <div className="text-white w-[400px] font-normal leading-normal text-[11px] md:text-base text-start">
                   Recevoir les notifications de la newsletter
                  </div>
                </div>

                {/* Toggle for Another Notification */}

                <div className="flex flex-row items-center gap-2 w-full">
                  <div
                    className={`${
                      isToggledNewsletter ? "bg-[#16DBCC]" : "bg-[#DFEAF2]"
                    }   min-w-[70px] h-[32px]  cursor-pointer select-none rounded-full relative`}
                    onClick={handleToggleNewsletter}
                  >
                    <div
                      className={`text-sm font-bold cursor-pointer bg-white text-white px-3 py-2 rounded-full flex justify-center items-center  
                      w-[30px] h-[30px] 
                     
                      absolute transition-all duration-300 ease-in-out 
                      ${
                        isToggledNewsletter
                          ? "left-[38px]"
                          : "left-0 sm:left-[2px] md:left-[2px] lg:left-[3px]"
                      }`}
                    >
                      {" "}
                    </div>
                  </div>
                  <div className="text-white w-[400px] font-normal leading-normal text-[11px] md:text-base text-start">
                   Recevoir les dernières nouveautés de la communauté par mail
                  </div>
                </div>
              </div>

              
            </div>
          </div>

          <SubmitButton
            buttonText="Confirmer les changements"
            type="submit"
            className="mt-6"
          />
        </form>
      </div>
    </div>
  );
};

export default Preferences;
