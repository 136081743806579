import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { FirebaseProvider } from './context/firebaseContext';
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from './context/GlobalContext';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalProvider>

  <FirebaseProvider>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App />
      <Toaster />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </FirebaseProvider>
  </GlobalProvider>

);

