import React from 'react'
import Discord from '../Images/Discord.svg'


export default function ButtonLeftIcon({ text, link }) {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <button className='rounded-[12px] bg-[#5865F2] flex gap-2  w-full h-full justify-center items-center p-4 md:p-2 text-[13px] text-white font-medium'>
                <img src={Discord} alt="" />
                <span>
                    {text}
                </span>
            </button>
        </a>
    )
}
