import React from 'react'

const StripeSkeleton = () => {
  return (
    <>
  <div className="bg-[#ffffff12] py-5 rounded-lg animate-pulse">
    {/* Header Skeleton */}
    {/* <div className="w-full flex justify-between py-2 px-10">
      <div className="h-6 bg-gray-600 rounded w-2/3"></div>
      <div className="h-6 bg-gray-600 rounded w-1/4"></div>
    </div> */}

    {/* Discount Section Skeleton */}
    {/* <div className="w-full bg-[#060b26] py-2 px-10 flex justify-between z-20">
      <div className="h-6 bg-gray-500 rounded w-1/2"></div>
      <div className="h-6 bg-gray-500 rounded w-1/4"></div>
    </div> */}

    {/* Tax Section Skeleton */}
    {/* <div className="flex justify-between items-center py-2 px-10">
      <div className="h-5 bg-gray-500 rounded w-1/3"></div>
      <div className="h-5 bg-gray-500 rounded w-1/5"></div>
    </div>
    <div className="h-[1px] mx-auto my-4 w-[90%] bg-[#ffffff36]"></div> */}

    {/* Total Amount Skeleton */}
    {/* <div className="flex justify-between items-center py-2 px-10">
      <div className="h-5 bg-gray-500 rounded w-1/3"></div>
      <div className="h-5 bg-gray-500 rounded w-1/5"></div>
    </div> */}

    {/* Payment Information Skeleton */}
    <div className="p-10 py-5">
      <div className="h-8 bg-gray-500 rounded w-2/3 mb-6"></div>

      <div className="grid grid-cols-1 gap-4">
        <div className="space-y-4">
          <div className="h-4 bg-gray-500 rounded w-1/3 mb-1"></div>
          <div className="h-10 bg-gray-600 rounded"></div>

          <div className="h-4 bg-gray-500 rounded w-1/3 mb-1"></div>
          <div className="h-10 bg-gray-600 rounded"></div>

          <div className="h-4 bg-gray-500 rounded w-1/3 mb-1"></div>
          <div className="h-10 bg-gray-600 rounded"></div>

          <div className="h-4 bg-gray-500 rounded w-1/3 mb-1"></div>
          <div className="h-10 bg-gray-600 rounded"></div>
        </div>

        {/* Payment Method Skeleton */}
        <div>
          <div className="h-4 bg-gray-500 rounded w-1/3 mb-4"></div>
          <div className="space-y-3">
            <div className="h-10 bg-gray-600 rounded"></div>
            <div className="h-10 bg-gray-600 rounded"></div>
            <div className="h-10 bg-gray-600 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p className="mt-2 text-center text-sm text-gray-600">
    <span className="h-4 bg-gray-500 rounded w-1/4 inline-block"></span>
  </p>
</>

  )
}

export default StripeSkeleton
