import React from "react";

export default function PrimaryButton({
  buttonText,
  buttonColor,
  onClick,
}) {
  return (
    <div>
      <span
        className={`primary-btn flex w-full p-[10px] justify-center items-center rounded-[7px] text-white font-bold text-[12px] uppercase ${buttonColor}`}
        onClick={onClick}
      >
        {buttonText}
      </span>
    </div>
  );
}
