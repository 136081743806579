import React, { useState } from 'react';

const PaymentOptions = ({ onMethodChange }) => {
  const [selectedMethod, setSelectedMethod] = useState('stripe');

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
    onMethodChange(method);
  };

 
  return (
    <div className="my-4 flex gap-4 items-center  text-gray-300 text-sm">
          <select
            value={selectedMethod}
            onChange={(e) => handleMethodChange(e.target.value)}
            className="w-full px-4 py-3 bg-[#161B2F] border border-gray-700 rounded-lg text-white"
          >
            <option value="stripe" className='bg-[#161B2F] text-white'>Carte bancaire</option>
            <option  value="paypal" className='bg-[#161B2F] text-white'>PayPal</option>
            <option  disabled value="googlepay" className='bg-[#161B2F] text-white'>Apple Pay</option>
          </select>
    </div>
  );
};

export default PaymentOptions;