import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getIdTokenResult } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";

const useUserRole = () => {
  const [user, setUser] = useState(null);
  const [pseudo, setPseudo] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [score, setScore] = useState()

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const docRef = doc(db, "users", currentUser.uid)
          const userData = (await getDoc(docRef)).data()
          setScore(userData.totalScore || 0)
          setPseudo(userData.pseudo || currentUser.email.split('@')[0])
          setUser(currentUser);

          const tokenResult = await getIdTokenResult(currentUser);
          const claims = tokenResult.claims;
          if (claims?.isAdmin) {
            setRole("admin");
          } else {
            setRole("user");
          }
        } catch (err) {
          setError(err);
        }
      } else {
        setUser(null);
        setRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { user, role, loading, error, score, pseudo };
};

export default useUserRole;
