import React, { useState, useEffect } from "react";
import SubmitButton from "../Components/SubmitButton";
import { useForm } from "react-hook-form";
import { useFirebase } from "../context/firebaseContext";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";

const RewardForm = ({ reward, onClose }) => {
  const [pdfName, setPdfName] = useState("");
  const { addRewards, updateReward } = useFirebase();
  const [unlockOptions, setUnlockOptions] = useState([]);

  const { register, handleSubmit, setValue, clearErrors, reset, watch } = useForm({
    defaultValues: {
      name: "",
      type: "image",
      unlockConditionType: "",
      unlockAfter: { id: "", title: "" },
      description: "",
      status: "locked",
    }
  });

  const selectedUnlockConditionType = watch("unlockConditionType");

  useEffect(() => {
    if (reward) {
      setValue("name", reward.name);
      setValue("type", reward.type);
      setValue("unlockConditionType", reward.unlockConditionType || "");
      setValue("unlockAfter", reward.unlockAfter || { id: "", title: "" });
      setValue("description", reward.description);
      setValue("status", reward.status || "locked");
      setPdfName(reward.url ? reward.url.split("/").pop() : "");
    }
  }, [reward, setValue]);

  useEffect(() => {
    if (selectedUnlockConditionType) {
      fetchUnlockOptions(selectedUnlockConditionType);
    }
  }, [selectedUnlockConditionType]);

  const fetchUnlockOptions = async (type) => {
    try {
      const collectionRef = collection(db, type);
      const snapshot = await getDocs(collectionRef);
      const options = snapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title
      }));
      setUnlockOptions(options);
    } catch (error) {
      console.error("Error fetching unlock options:", error);
    }
  };

  const handleValidation = () => {
    clearErrors();
    return true;
  };

  

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type === "application/pdf") {
        setPdfName(file.name);
      }
    }
  };

  const handleUnlockAfterChange = (e) => {
    const selectedOption = unlockOptions.find(option => option.id === e.target.value);
    setValue("unlockAfter", { id: selectedOption.id, title: selectedOption.title });
  };

  const onSubmit = async (data) => {
    const { name, type, description, status, url: fileInput, unlockConditionType, unlockAfter } = data;
    try {
      let downloadURL = reward ? reward.url : null;

      if (fileInput && fileInput.length > 0) {
        const file = fileInput[0];
        const storage = getStorage();

        console.log(file, "reward File")
        const metadata = {
          contentType: file.type,
          name: file.name,
          fileName: file.name,
          size: file.size,
          contentDisposition: `attachment; filename=${file.name}`,
        };
        const storageRef = ref(
          storage,
          `rewards/${type === "image" ? "images" : "pdfs" ? "pdfs": "json" }/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null,
            (error) => {
              console.error("Upload failed", error);
              reject(error);
            },
            async () => {
              downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      }

      const rewardData = {
        name,
        url: downloadURL || reward?.url,
        type,
        unlockConditionType,
        unlockAfter,
        description,
        status,
        pdfName: pdfName || (reward && reward.pdfName),
        isActive: false,
      };

      if (reward) {
        await updateReward("rewards", reward.id, rewardData);
      } else {
        await addRewards("rewards", rewardData);
      }

      console.log("Reward saved successfully!");
      reset();
      onClose();
    } catch (error) {
      console.error("Error saving reward:", error);
    }
  };

  return (
    <div>
      <main className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border">
        <h2 className="text-xl text-start font-medium text-white py-4 border-b mb-6">
          {reward ? "Update reward" : "Add reward"}
        </h2>

        <div className="flex flex-col md:flex-row justify-start gap-[1px]">
          <form
            onSubmit={handleSubmit((data) => {
              if (handleValidation()) {
                onSubmit(data);
              }
            })}
            className="w-[100%] flex flex-col gap-6"
          >
            <label className="flex flex-col">
              <span className="text-white text-lg flex justify-start mb-1">
                Name
              </span>
              <input
                type="text"
                id="name"
                placeholder="Reward Name"
                {...register("name")}
                style={{ backgroundColor: "transparent" }}
                className="py-2 px-3 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
              />
            </label>

            <label className="flex flex-col">
              <span className="text-white text-lg flex justify-start mb-1">
                Type
              </span>
              <select
              
                id="type"
                {...register("type")}
                style={{ backgroundColor: "transparent" }}
                className="py-2 px-3 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
              >
                <option value="image" className="bg-bgColor">
                  Image
                </option>
                <option value="pdf" className="bg-bgColor">
                  Pdf
                </option>
                <option value="json" className="bg-bgColor">
                  Json
                </option>
              </select>
            </label>

            <label className="flex flex-col">
              <span className="text-white text-lg flex justify-start mb-1">
                Status
              </span>
              <select
                id="status"
                {...register("status")}
                style={{ backgroundColor: "transparent" }}
                className="py-2 px-3 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
              >
                <option value="locked" className="bg-bgColor">
                  Locked
                </option>
                <option value="unlocked" className="bg-bgColor">
                  Unlocked
                </option>
              </select>
            </label>

            <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
              <label
                className={`flex flex-col ${reward ? "w-[50%]" : "w-full"}`}
              >
                <span className="text-white text-lg flex justify-start mb-1">
                  URL
                </span>
                <input
                  type="file"
                  id="url"
                  accept="image/*, .pdf, .json"
                  {...register("url")}
                  onChange={handleFileChange}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2 px-3 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
                />
              </label>

              {reward && (
                <div className="w-[50%]">
                  <div className="flex items-center justify-center mt-4 text-white">
                    {reward.type === "pdf" ? (
                      <div className="w-full">
                        <p className="break-words">
                          {reward.pdfName || "No PDF selected"}
                        </p>
                      </div>
                    ) : (
                      <img
                        src={reward.url}
                        alt="Preview"
                        className="object-fit rounded-[50%] w-16 h-16"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            <label className="flex flex-col">
              <span className="text-white text-lg flex justify-start mb-1">
                Unlock Condition Type
              </span>
              <select
                id="unlockConditionType"
                {...register("unlockConditionType")}
                style={{ backgroundColor: "transparent" }}
                className="py-2 px-3 rounded-lg border border-[#ffffff1a] w-full text-white  placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
              >
                <option value="" className="text-gray-500">Select unlock condition</option>
                <option value="sections" className="text-gray-500">Sections</option>
                <option value="modules" className="text-gray-500">Modules</option>
                <option value="quizes" className="text-gray-500">Quizzes</option>
                <option value="videos" className="text-gray-500">Videos</option>
              </select>
            </label>

            {selectedUnlockConditionType && (
              <label className="flex flex-col">
                <span className="text-white text-lg flex justify-start mb-1">
                  Unlock After
                </span>
                <select
                  id="unlockAfterId"
                  onChange={handleUnlockAfterChange}
                  style={{ backgroundColor: "transparent" }}
                  className="py-2 px-3 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
                >
                  <option value="" className="text-gray-500">Select {selectedUnlockConditionType}</option>
                  {unlockOptions.map((option) => (
                    <option key={option.id} value={option.id} className="text-gray-500">
                      {option.title}
                    </option>
                  ))}
                </select>
              </label>
            )}

            <label className="flex flex-col">
              <span className="text-white text-lg flex justify-start mb-1">
                Description
              </span>
              <textarea
                rows={"3"}
                placeholder="Description"
                id="description"
                {...register("description")}
                style={{ backgroundColor: "transparent" }}
                className="py-4 px-3 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white placeholder:opacity-50 focus:border-[#ffffff40] outline-none"
              />
            </label>

            <SubmitButton
              buttonText={reward ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </main>
    </div>
  );
};

export default RewardForm;