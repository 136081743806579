// DonutChart.js
import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ data, title, description }) => {
  const chartRef = useRef(null);

  const chartData = {
    datasets: [
      {
        data: [data, 100 - data],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart rendering.
            return null;
          }

          // Create gradient
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, '#614AD3');
          gradient.addColorStop(1, '#E42C64');

          return [gradient, '#343B5C']; // Gradient for actual data, solid color for the rest
        },
        hoverBackgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart rendering.
            return null;
          }

          // Create gradient
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, '#E42C64');
          gradient.addColorStop(1, '#614AD3');

          return [gradient, '#343B5C']; // Gradient for actual data, solid color for the rest
        },
        borderWidth: 0,
        borderRadius: 10,
        cutout: '92%',
        z: 2, // Set z index higher
      }
    ]
  };

  const options = {
    cutout: '70%',  // Adjust the inner radius
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };

  return (
    <div className='relative lg:w-[130px]  lg:h-[130px]  w-[90px] h-[90px] m-3' >
      <Doughnut ref={chartRef} data={chartData} options={options} />
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '24px',
        color: 'white',
        fontWeight: 'bold'
      }}>
        {data}%
      </div>
      <div style={{ marginTop: '10px', color: 'white', textAlign: 'center' }}>{title}</div>
      <div style={{ color: 'white', textAlign: 'center' }}>{description}</div>
    </div>
  );
};

export default DonutChart;
