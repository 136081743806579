import React, { useState, useRef, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import useUserRole from "../hooks/useUserRole";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from 'uuid';

const VideoForm = ({ video, onClose }) => {
  const [formData, setFormData] = useState({
    file: null,
    thumbnailFile: null,
    thumbnailUrl: null,
    title: "",
    description: "",
    createdBy: "",
    order: null,
    status: "inactive",
    module: { title: "", moduleId: "" },
    type: "video",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    video: 0,
    thumbnail: 0,
  });
  const [videoDuration, setVideoDuration] = useState(0);
  const [availableModules, setAvailableModules] = useState([]);
  const fileInputRef = useRef(null);
  const thumbnailInputRef = useRef(null);

  const { addVideo, updateVideo } = useFirebase();
  const { user } = useUserRole();

   const fetchModules = async () => {
    try {
      const moduleCollection = await getDocs(collection(db, "modules"));
      const moduleData = moduleCollection.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        section: doc.data().section,
      }));
      setAvailableModules(moduleData);
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  useEffect(() => {
    fetchModules();
    if (video) {
      setFormData({
        title: video.title,
        description: video.description,
        duration: video.videoDuration,
        createdBy: video.createdBy,
        status: video.status,
        order: video.order,
        type: video.type,
        thumbnailUrl: video.thumbnailUrl,
        module: { title: video.module.title, moduleId: video.module.moduleId },
      });
    }
  }, [video]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file" || name === "thumbnailFile") {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      if (name === "file") {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(file);
        videoElement.onloadedmetadata = () => {
          setVideoDuration(videoElement.duration);
        };
      }

      if (name === "thumbnailFile") {
        setFormData((prevData) => ({
          ...prevData,
          thumbnailUrl: null, 
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleModuleChange = (e) => {
    const selectedModule = availableModules.find(
      (module) => module.id === e.target.value
    );
    setFormData((prevData) => ({
      ...prevData,
      module: { title: selectedModule.title, moduleId: selectedModule.id },
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!video && !formData.file) newErrors.file = "Video file is required";
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.description)
      newErrors.description = "Description is required";
    if (!formData.order) newErrors.order = "Order is required";
    if (!formData.status) newErrors.status = "Status is required";
    if (!formData.module.moduleId)
      newErrors.module = "Module selection is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadFileToFirebase = async (file, type) => {
    const uuid = uuidv4()
    const storage = getStorage();
    const storageRef = ref(storage, `${type}s/${uuid}.${file.name.split(".")[1]}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [type]: progress,
          }));
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        let videoData = {
          title: formData.title,
          description: formData.description,
          createdBy: user?.email.split("@")[0],
          order: +formData.order,
          status: formData.status,
          module: formData.module,
          type: formData.type,
        };
  
        const uploadPromises = [];
  
        if (formData.file) {
          const formattedDuration = formatDuration(videoDuration);
          uploadPromises.push(uploadFileToFirebase(formData.file, "video"));
          videoData.videoDuration = formattedDuration;
        }
  
        if (formData.thumbnailFile) {
          uploadPromises.push(uploadFileToFirebase(formData.thumbnailFile, "thumbnail"));
        }
  
        let uploadResults = await Promise.all(uploadPromises);
  
        if (uploadPromises.length > 0) {
          if (formData.file) {
            videoData.url = uploadResults.shift();
          }
          if (formData.thumbnailFile) {
            videoData.thumbnailUrl = uploadResults.shift();
          }
        }
        
        if (!formData.thumbnailFile && video) {
          videoData.thumbnailUrl = video.thumbnailUrl;
        }
        let res;
        if (video) {
          if (!formData.file) {
            videoData.url = video.url;
          }
          res = await updateVideo(video.id, videoData);
        } else {
          res = await addVideo("videos", videoData);
        }
  
        if (res.status === "success") {
          toast.success(res.message, { position: "top-center" });
          onClose();
        } else {
          toast.error(res.message, { position: "top-center" });
        }
      } catch (error) {
        toast.error(error.message, { position: "top-center" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formatDuration = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border overflow-y-auto h-[850px] scrollbar-thin scrollbar-thumb-[#101490] scrollbar-thumb-rounded-md"
    >
      <h2 className="text-xl text-start font-medium text-white py-4 border-b mb-6">
        {video ? "Update Video" : "Add Video"}
      </h2>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="text-lg font-normal text-white mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Video Title"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-500">{errors.title}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <div className="flex justify-between items-center w-full">
          <label htmlFor="file" className="text-lg font-normal text-white mb-1">
            Video File
          </label>
          {video && (
            <div className="text-white">
              Current video :{" "}
              <span className="text-red-700">{video.title}</span>
            </div>
          )}
        </div>
        <input
          type="file"
          id="file"
          name="file"
          ref={fileInputRef}
          accept="video/*"
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.file && (
          <p className="mt-1 text-sm text-red-500">{errors.file}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="thumbnailFile"
          className="text-lg font-normal text-white mb-1"
        >
          Thumbnail
        </label>
        <input
          type="file"
          id="thumbnailFile"
          name="thumbnailFile"
          ref={thumbnailInputRef}
          accept="image/*"
          onChange={handleChange}
          className="w-full py-2 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.thumbnail && (
          <p className="mt-1 text-sm text-red-500">{errors.thumbnail}</p>
        )}
        {(formData.thumbnailUrl || formData.thumbnailFile) && (
          <img
            src={formData.thumbnailFile ? URL.createObjectURL(formData.thumbnailFile) : formData.thumbnailUrl}
            alt="Current thumbnail"
            className="mt-2 w-20 h-10 object-cover"
          />
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="order" className="text-lg font-normal text-white mb-1">
          Order
        </label>
        <input
          type="number"
          id="order"
          name="order"
          value={formData.order}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="video order"
        />
        {errors.order && (
          <p className="mt-1 text-sm text-red-500">{errors.order}</p>
        )}
      </div>

      <div className="mb-4 flex flex-col items-start">
        <label
          htmlFor="description"
          className="text-start text-lg font-normal text-white mb-1"
        >
          Description
        </label>
        <ReactQuill
          value={formData.description}
          onChange={(content) =>
            handleChange({ target: { name: "description", value: content } })
          }
          className="w-full bg-transparent text-white rounded-[15px]"
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          }}
        />
        {errors.description && (
          <p className="mt-1 text-sm text-red-500">{errors.description}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="status" className="text-lg font-normal text-white mb-1">
          Status
        </label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>
            Select status
          </option>
          <option value={"locked"} className="text-black">
            Locked
          </option>
          <option value={"unlocked"} className="text-black">
            Un-locked
          </option>
          <option value={"inactive"} className="text-black">
            Inactive
          </option>
          <option value={"completed"} disabled className="text-gray-400">
            Completed
          </option>
        </select>
        {errors.status && (
          <p className="mt-1 text-sm text-red-500">{errors.status}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="module" className="text-lg font-normal text-white mb-1">
          Module
        </label>
        <select
          id="module"
          name="module"
          value={formData.module.moduleId}
          onChange={handleModuleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="" disabled>
            Select a module
          </option>
          {availableModules.map((module) => (
            <option key={module.id} value={module.id} className="text-black">
              {module.title}-{module.section.title}
            </option>
          ))}
        </select>
        {errors.module && (
          <p className="mt-1 text-sm text-red-500">{errors.module}</p>
        )}
      </div>
      {uploadProgress.video > 0 && (
        <div className="mb-4">
          <h3 className="text-white mb-2">Upload Progress</h3>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2 relative">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${uploadProgress.video}%` }}
            ></div>
            <span className="absolute right-0 top-2 text-white text-sm">
              Video: {uploadProgress.video.toFixed(1)}%
            </span>
          </div>
        </div>
      )}

      <div className="flex">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-6 py-2 bg-primaryBlue text-white rounded-lg font-normal ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-primaryBlue"
          }`}
        >
          {isLoading ? "Processing..." : video ? "Update Video" : "Add Video"}
        </button>
      </div>
    </form>
  );
};

export default VideoForm;
