import React from 'react';

// Individual ranking item skeleton
const RankingItemSkeleton = ({ isOwnRank = false }) => {
  // Mobile view
  const MobileView = () => (
    <div className="flex justify-between items-center mt-5 lg:hidden">
      <div className="flex gap-1 md:gap-3 items-center">
        <div className="w-6 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Rank number */}
        <div className="w-[40px] h-[40px] bg-gray-200/20 rounded-lg animate-pulse" /> {/* Avatar */}
        <div className="flex flex-col justify-start">
          <div className="w-24 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Name */}
        </div>
      </div>
      <div className="flex justify-start">
        <div className="w-16 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Score */}
      </div>
    </div>
  );

  // Desktop view
  const DesktopView = () => (
    <div className="hidden lg:flex flex-col justify-start gap-[30px]">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="p-[10px_0px]">
              <div className="flex gap-1 md:gap-0 items-center">
                <div className="w-6 h-4 bg-gray-200/20 rounded animate-pulse mr-4" /> {/* Rank */}
                <div className="w-10 h-10 bg-gray-200/20 rounded-lg mr-4 animate-pulse" /> {/* Avatar */}
                <div className="w-32 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Name */}
              </div>
            </td>
            <td className="text-start">
              <div className="w-20 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Score */}
            </td>
            <td className="hidden lg:flex lg:flex-col lg:justify-center mt-4">
              <div className="w-24 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Function */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      <MobileView />
      <DesktopView />
    </>
  );
};

// Main ranking page skeleton
const RankingSkeleton = () => {
  return (
    <div className="flex flex-col h-screen">
      {/* Header Skeleton */}
      <div className="w-[100%] mb-6">
        <div className="h-8 w-48 bg-gray-200/20 rounded animate-pulse" />
      </div>

      {/* Content */}
      <div className="flex flex-col gap-6 overflow-hidden">
        {/* Own Ranking Card */}
        <div className="flex p-[17px_22px] flex-col rounded-[15px] border border-white/30 bg-black/65 bg-primary">
          <div className="w-64 h-6 bg-gray-200/20 rounded animate-pulse mb-4" /> {/* Title */}
          <table className="">
            <thead className="lg:hidden top-0 bg-primary">
              <tr className="border-b border-[#ffffff1a] flex justify-between">
                <th className="text-start py-4 w-24">
                  <div className="h-3 bg-gray-200/20 rounded animate-pulse" />
                </th>
                <th className="text-start py-4 w-24">
                  <div className="h-3 bg-gray-200/20 rounded animate-pulse" />
                </th>
              </tr>
            </thead>
          </table>
          <RankingItemSkeleton isOwnRank={true} />
        </div>

        {/* All Rankings */}
        <div className="all-ranking bg-primary pt-4 p-[17px_22px] rounded-[15px] border border-white/30 overflow-hidden">
          <div className="flex flex-col justify-start gap-[30px]">
            {/* Desktop Header */}
            <div className="hidden lg:block mb-2">
              <div className="w-48 h-6 bg-gray-200/20 rounded animate-pulse mb-2" /> {/* Title */}
              <div className="w-64 h-4 bg-gray-200/20 rounded animate-pulse" /> {/* Subtitle */}
            </div>

            {/* Rankings Table */}
            <div className="w-full h-[520px] overflow-y-auto no-scrollbar">
              <table className="w-full">
                <thead className="sticky top-0 bg-primary">
                  <tr className="border-b py-2 border-[#ffffff1a]">
                    <th className="text-start py-4 lg:ps-10 w-[62%]">
                      <div className="h-4 bg-gray-200/20 rounded animate-pulse w-24" />
                    </th>
                    <th className="text-end lg:text-start py-4 w-[22%]">
                      <div className="h-4 bg-gray-200/20 rounded animate-pulse w-20" />
                    </th>
                    <th className="hidden lg:inline-block py-4 w-[40%]">
                      <div className="h-4 bg-gray-200/20 rounded animate-pulse w-24" />
                    </th>
                  </tr>
                </thead>
                <tbody className="w-[100%]">
                  {/* Generate 10 skeleton items */}
                  {[...Array(10)].map((_, index) => (
                    <tr key={index} className="border-b border-[#ffffff1a]">
                      <td colSpan={3}>
                        <RankingItemSkeleton />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingSkeleton;