import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getAuth,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import ProfileImage from "../Images/Avatar.png";
import { GoPencil } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { db } from "../firebase/config";
import toast from "react-hot-toast";

export default function ProfileModify() {
  const auth = getAuth();
  const user = auth.currentUser;

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: user?.displayName,
      email: user?.email,
      date: "",
      residence: "",
      pseudo: "",
      phoneNumber: "",
      fonction: "",
    },
  });

  const [currentError, setCurrentError] = useState("");
  const [countries, setCountries] = useState([]);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const uploadProfileImage = async (file) => {
    if (file) {
      const storage = getStorage();
      const metadata = {
        contentType: file.type,
        name: file.name,
        fileName: file.name,
        size: file.size,
        contentDisposition: `attachment; filename=${file.name}`,
      };
      const storageRef = ref(
        storage,
        `images/profile/${user.uid}/${file.name}`
      );

      console.log("Storeage Ref", storageRef);

      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      setIsLoading(true);
      uploadTask.on(
        "state_changed",

        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error("Échec du téléchargement", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const docRef = doc(db, "users", user.uid)

          await setDoc(docRef, {
            photoURL: downloadURL
          }, {merge:true})
          await updateProfile(user, { photoURL: downloadURL }).then(
            () => {
              toast.success("Image de profil téléchargée avec succès...", {
                position: "top-center",
              });
              setIsLoading(false);
            }
          );
        }
      );
    }
    return null;
  };



  const onSubmit = async (data) => {
    try {
      if (data.email !== user.email) {
        await updateEmail(user, data.email);
      }
   
      const profileUpdates = {
        displayName: data.name,
        phoneNumber: data.phoneNumber,
      };

      console.log("Data", profileUpdates);
      await updateProfile(user, profileUpdates);

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, {
        date: data?.date,
        residence: data?.residence,
        pseudo: data?.pseudo,
        fonction: data?.fonction,
      });

      toast.success("Profil mis à jour avec succès !", {
        position: "top-center",
      });
    } catch (error) {
      setCurrentError(error.message);
    }
  };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const countryNames = data.map((country) => country.name.common);
        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  
  const handleValidation = () => {
    const values = getValues();
    clearErrors();
    setCurrentError("");
 
    if (!values.email) {
      setError("email", { type: "required", message: "L'e-mail est requis" });
      setCurrentError("Email is required");
      return false;
    }
    setCurrentError("");
    return true;
  };

  return (
    <>
      <div className="flex flex-col gap-[30px] py-[30px] px-0">
        {currentError && (
          <div className="flex h-[60px] p-4 px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-[fit-content] mt-4">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1">
                  <g id="Icon-Set-Filled">
                    <path
                      id="cross-circle"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                      fill="#CE0500"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div>{currentError}</div>
          </div>
        )}
        <div className="flex  lg:gap-[130px] flex-col items-center lg:items-start lg:flex-row lg:justify-start">
          <div className="w-[147px] h-[147px] relative flex md:hidden justify-center items-center">
            <img src={user?.photoURL || ProfileImage} alt="" className="rounded-lg" />
            <label
              htmlFor="profile-img"
              className="absolute cursor-pointer w-[31px] h-[31px] top-[80px] left-[80px] p-2 bg-white rounded-[44px]"
            >
              <GoPencil className="text-[#0075FF]" />
            </label>
            <input
              id="profile-img"
              type="file"
              accept="image/*"
              onChange={(e) => uploadProfileImage(e.target.files[0])}
              className="py-4 hidden px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-gray-500"
            />
             {isLoading && (
              <div className="progress-bar w-full border border-gray-100 rounded-lg h-2">
                <div className={`w-[${progress}%] bg-slate-100 h-full`}>{}</div>
              </div>
            )}
          </div>

          <div className="w-[147px] h-[147px] relative hidden md:block">
            <img src={user?.photoURL || ProfileImage} alt="" className="rounded-full" />
            <label
              htmlFor="profile-img"
              className="absolute cursor-pointer w-[31px] h-[31px] top-[80px] left-[80px] p-2 bg-white rounded-[44px]"
            >
              <GoPencil className="text-[#0075FF]" />
            </label>
            <input
              id="profile-img"
              type="file"
              accept="image/*"
              onChange={(e) => uploadProfileImage(e.target.files[0])}
              className="py-4 hidden px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-gray-500"
            />
            {isLoading && (
              <div className="progress-bar w-full border border-gray-100 rounded-lg h-2">
                <div className={`w-[${progress}%] bg-slate-100 h-full`}>{}</div>
              </div>
            )}
          </div>

          <form
            onSubmit={handleSubmit((data) => {
              if (handleValidation()) {
                onSubmit(data);
              }
            })}
            className="w-full"
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-4 lg:[40px] xl:gap-[130px]">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span className="text-white font-normal text-[13px] md:text-[16px] flex justify-start">
                    Nom et prénom
                  </span>
                  <div className="relative">
                    <input
                      type="text"
                      id="name"
                      placeholder="Nom et prénom"
                      {...register("name")}
                      className="bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                    />
                    {errors.name && (
                      <RxCross2 className="bg-[red] rounded-full p-[2px] h-6 w-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-lg" />
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-white font-normal text-[13px] md:text-[16px] text-start">
                    Adresse mail
                  </span>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      {...register("email")}
                      placeholder="email@domain.com"
                      className="bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                    />
                    {errors.email && (
                      <RxCross2 className="bg-[red] rounded-full p-[2px] h-6 w-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-lg" />
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-white font-normal text-[13px] md:text-[16px] text-start">
                    Date de naissance
                  </span>
                  <div className="relative">
                    <input
                      id="date"
                      {...register("date")}
                      type="date"
                      className="bg-transparent py-[9px] px-4 md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px] appearance-none"
                    />

                    {errors.date && (
                      <RxCross2 className="bg-[red] rounded-full p-[2px] h-6 w-6 absolute right-12 top-1/2 transform -translate-y-1/2 text-lg" />
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                <label className="text-white font-normal text-[13px] md:text-[16px] text-start">Pays de résidence</label>
                <select
                  {...register("residence")}
                  className="bg-transparent py-[9px] px-4 text-white   md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full "
                >
                  <option value="" className="text-gray-500">Sélectionnez un pays</option>
                  {countries.map((country) => (
                    <option key={country} value={country} className="text-gray-500">
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span className="text-white font-normal text-[13px] md:text-[16px] text-start">
                    Pseudo
                  </span>
                  <div className="relative">
                    <input
                      id="pseudo"
                      {...register("pseudo")}
                      type="text"
                      placeholder="Pseudo"
                      className="bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                    />
                    {errors.pseudo && (
                      <RxCross2 className="bg-[red] rounded-full p-[2px] h-6 w-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-lg" />
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-white font-normal text-[13px] md:text-[16px] text-start">
                    Numéro de téléphone
                  </span>
                  <div className="relative">
                    <input
                      id="phoneNumber"
                      {...register("phoneNumber")}
                      type="number"
                      placeholder="Numéro de téléphone"
                      className="bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                    />
                    {errors.phoneNumber && (
                      <RxCross2 className="bg-[red] rounded-full p-[2px] h-6 w-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-lg" />
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-white font-normal text-[13px] md:text-[16px] text-start">
                    Fonction professionnelle
                  </span>
                  <div className="relative">
                    <input
                      id="fonction"
                      {...register("fonction")}
                      type="text"
                      placeholder="Fonction professionnelle"
                      className="bg-transparent py-[9px] px-4  md:py-4 md:px-6 rounded-[15px] border-[1px] border-[#ffffff1a] w-full text-white box-border outline-none focus:border-[#ffffff40] placeholder:text-white placeholder:text-[12px] md:placeholder:text-[14px]"
                    />
                    {errors.fonction && (
                      <RxCross2 className="bg-[red] rounded-full p-[2px] h-6 w-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-lg" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 w-full">
              <button
                type="submit"
                className="w-full sm:w-auto font-light flex h-12 py-4 px-5 justify-center items-center gap-2.5 rounded-[15px] bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1] text-white text-base md:font-medium text-center text-[11px] md:text-[0.890rem]"
              >
                Confirmer les changements
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
