import React from "react";

export default function DocumentationCard() {
  return (
    <div className="p-[15px] h-[173px] w-[217px] rounded-[12px] border border-[#113BE1] bg-[#000000a6] flex flex-col gap-[10px]">
      <div>
        <span className="p-[7.5px] bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1] rounded-[5px] flex items-center justify-center w-fit">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            className="hover-svg"
          >
            <g clipPath="url(#clip0_1061_1820)">
              <path
                d="M8.16289 3.49384C8.11927 3.45212 8.06124 3.42883 8.00088 3.42883C7.94052 3.42883 7.88248 3.45212 7.83887 3.49384L2.44531 8.64628C2.42241 8.6682 2.40418 8.69453 2.39175 8.72368C2.37931 8.75284 2.37291 8.78421 2.37295 8.81591L2.37207 13.6517C2.37207 13.9003 2.47084 14.1387 2.64666 14.3146C2.82247 14.4904 3.06093 14.5892 3.30957 14.5892H6.125C6.24932 14.5892 6.36855 14.5398 6.45645 14.4519C6.54436 14.364 6.59375 14.2447 6.59375 14.1204V10.136C6.59375 10.0739 6.61844 10.0143 6.6624 9.9703C6.70635 9.92635 6.76596 9.90165 6.82812 9.90165H9.17187C9.23403 9.90165 9.29365 9.92635 9.3376 9.9703C9.38155 10.0143 9.40625 10.0739 9.40625 10.136V14.1204C9.40625 14.2447 9.45563 14.364 9.54354 14.4519C9.63145 14.5398 9.75068 14.5892 9.875 14.5892H12.6893C12.9379 14.5892 13.1764 14.4904 13.3522 14.3146C13.528 14.1387 13.6268 13.9003 13.6268 13.6517V8.81591C13.6268 8.78421 13.6204 8.75284 13.608 8.72368C13.5955 8.69453 13.5773 8.6682 13.5544 8.64628L8.16289 3.49384Z"
                fill="white"
                className="path-1"
              />
              <path
                d="M14.8825 7.68005L12.6911 5.58357V2.40222C12.6911 2.2779 12.6417 2.15867 12.5538 2.07077C12.4659 1.98286 12.3467 1.93347 12.2223 1.93347H10.8161C10.6918 1.93347 10.5725 1.98286 10.4846 2.07077C10.3967 2.15867 10.3473 2.2779 10.3473 2.40222V3.33972L8.65047 1.71726C8.49168 1.55671 8.25555 1.46472 8.00037 1.46472C7.74608 1.46472 7.51053 1.55671 7.35174 1.71755L1.1203 7.67947C0.938073 7.85525 0.915221 8.14441 1.08104 8.33484C1.12268 8.38291 1.17367 8.422 1.23089 8.44973C1.28812 8.47747 1.3504 8.49327 1.41392 8.49618C1.47745 8.49908 1.54091 8.48903 1.60043 8.46663C1.65995 8.44423 1.71429 8.40996 1.76014 8.36589L7.83924 2.55691C7.88286 2.51518 7.94089 2.4919 8.00125 2.4919C8.06161 2.4919 8.11965 2.51518 8.16327 2.55691L14.2429 8.36589C14.3325 8.45178 14.4525 8.49865 14.5765 8.49623C14.7006 8.49381 14.8186 8.44231 14.9048 8.353C15.0846 8.16667 15.0697 7.85906 14.8825 7.68005Z"
                fill="white"
                className="path-2"
              />
            </g>
            <defs>
              <clipPath id="clip0_1061_1820">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.5 0.526367)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
      <div>
        <h2 className="text-left font-bold text-[14px] text-white">Besoin d'aide ?</h2>
        <p className="text-left font-medium text-[12px]">
        Pose tes questions dans la communauté !
        </p>
      </div>
      <a
        className={`primary-btn flex w-full p-[10px] justify-center items-center rounded-[7px] text-white font-bold text-[12px] uppercase bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1`}
        href={"https://discord.com/invite/ZMPUNgMKgw"}
      >
        COMMUNAUTÉ
      </a>
    </div>
  );
}
