import React from 'react';

const SidebarSkeleton = () => {
  // Create an array of 6 items for skeleton nav links (average between user/admin items)
  const skeletonItems = Array(6).fill(null);

  return (
    <div className="flex flex-col h-[100%] min-h-[425px] w-[270px] border-r border-[rgba(144,157,172,0.2)] bg-[#071436] fixed left-0 top-0 z-20">
      {/* Logo Section */}
      <div className="p-6 border-b-[1px]">
        <div className="flex items-center gap-3 p-2">
          {/* Logo placeholder */}
          <div className="w-10 h-10 bg-gray-200 animate-pulse rounded-md" />
          {/* Text logo placeholder */}
          <div className="w-32 h-8 bg-gray-200 animate-pulse rounded-md" />
        </div>
      </div>

      <div className="flex-grow overflow-y-auto h-screen">
        <div className="p-6">
          {/* User info skeleton */}
          <div className="mb-6">
            <div className="flex items-center gap-3">
              {/* Avatar placeholder */}
              <div className="w-12 h-12 bg-gray-200 animate-pulse rounded-full" />
              <div className="flex-1">
                {/* Name placeholder */}
                <div className="w-24 h-4 bg-gray-200 animate-pulse rounded mb-2" />
                {/* Score placeholder */}
                <div className="w-16 h-3 bg-gray-200 animate-pulse rounded" />
              </div>
            </div>
          </div>

          {/* Nav links skeleton */}
          <nav className="NavLinks">
            <ul className="p-0">
              {skeletonItems.map((_, index) => (
                <li key={index} className="my-2.5">
                  <div className="flex items-center gap-2.5 py-2 px-4">
                    {/* Icon placeholder */}
                    <div className="w-8 h-8 bg-gray-200 animate-pulse rounded" />
                    {/* Text placeholder */}
                    <div className="w-24 h-4 bg-gray-200 animate-pulse rounded" />
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* Documentation card skeleton */}
      <div className="p-6">
        <div className="w-full h-32 bg-gray-200 animate-pulse rounded-lg" />
      </div>
    </div>
  );
};

export default SidebarSkeleton;