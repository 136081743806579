import './QuizLoader.css'

import React from 'react'

export default function QuizLoader({ quizCompleted, totalQuiz }) {
    return (
        <div className="loader-container">
            <div className="w-full h-[12px] lg:h-[16px] rounded-[9px] overflow-hidden mb-5 relative bg-[#9bffff40]">
                <div className="loader-fill"
                    style={{ width: `${(quizCompleted / totalQuiz) * 100}%` }}
                >
                </div>
            </div>
        </div>
    )
}
