import React, { useEffect, useState } from "react";

//page component
import RankingListItem from "../RankingComponents/RankingListItem";

//common component
import Header from "../Components/Header";

//import data
import profileImage from "../Images/Avatar.png";
//styles
import "./Ranking.css";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { auth, db } from "../firebase/config";
import RankingSkeleton from "../Skeleton/RankingSkeleton";

export default function Ranking({ setIsSidebarOpen, isSidebarOpen }) {
  const [rankings, setRankings] = useState(null);
  const [ownData, setOwnData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const usersQuery = query(
          collection(db, "users"), 
          orderBy("totalScore", "desc"), 
          limit(80)
        );

        const usersSnapshot = await getDocs(usersQuery);

        const userRankingsData = [];

        usersSnapshot.forEach((doc) => {
          const userData = {
            id: doc.id,
            self: Boolean(doc.id === auth?.currentUser.uid),
            ...doc.data(),
            totalScore: doc.data().totalScore ? doc.data().totalScore : 0,
          };
          userRankingsData.push(userData);
        });

        const sortedRankings = userRankingsData.sort(
          (a, b) => b.totalScore - a.totalScore
        );
        const selfRanking = sortedRankings.findIndex((user) => user?.self) + 1;
        const selfData = sortedRankings.find((user) => user?.self);
        const selfRankingData = { ranking: selfRanking, ...selfData };
        
        setOwnData(selfRankingData);
        setRankings(sortedRankings);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <RankingSkeleton />;
  }

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <div className="w-[100%]">
        <Header
          PageName={"Classement"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>

      {/* Content */}
      <div className="flex flex-col gap-6 overflow-hidden">
        <div className="flex p-[17px_22px] flex-col rounded-[15px] border border-white/30 bg-black/65 bg-primary ">
          <h2 className="text-left text-[16px] lg:text-[20px] font-semibold text-white">
            Mon rang dans le classement
          </h2>
          <table className="">
            <thead className="lg:hidden top-0 bg-primary ">
              <tr className="border-b text-start border-[#ffffff1a] flex justify-between">
                <th className="text-start py-4  font-normal text-[10px] text-[#A0AEC0]">
                  PSEUDO
                </th>
                <th className="text-start py-4  font-normal text-[10px] text-[#A0AEC0]"></th>
                <th className="text-start py-4 font-normal text-[#A0AEC0] text-[10px]">
                  SCORE
                </th>
              </tr>
            </thead>
          </table>

          <div>
            <RankingListItem 
              className="bottom-0"
              rankingSerial={ownData?.ranking}
              profileImage={ownData?.photoURL || profileImage}
              profileName={ownData?.pseudo || ownData?.email.split("@")[0]}
              profileEmail={ownData?.email}
              userScore={parseFloat(ownData?.totalScore).toFixed(2)}
              userDesignation={ownData?.fonction}
              officeType={"Organization"}
              myRankStyle={"mb-[10px] border-b-0"}
            />
          </div>
        </div>

        <div className="all-ranking bg-primary  pt-4 p-[17px_22px] rounded-[15px] border border-white/30 overflow-hidden">
          <div className="flex flex-col justify-start gap-[30px]">
            <div className="hidden lg:block mb-2">
              <h2 className="text-left text-[16px] md:text-[20px] font-semibold text-white">
                Classement
              </h2>
              <h3 className="text-[#A0AEC0] text-[12px] md:text-[14px] text-left">
                Le Pouvoir de l’IA n’attend que toi..
              </h3>
            </div>

            <div className="w-full h-[520px] overflow-y-auto no-scrollbar  text-white">
              <table className="w-full">
                <thead className="sticky top-0 bg-primary">
                  <tr className="border-b py-2 border-[#ffffff1a]">
                    <th className="text-start py-4 lg:ps-10 font-normal text-[10px] lg:text-[16px] text-[#A0AEC0] lg:text-white w-[62%]">
                      PSEUDO
                    </th>
                    <th className="text-end lg:text-start py-4 font-normal text-[10px] lg:text-[16px] text-[#A0AEC0] lg:text-white w-[22%]">
                      SCORE
                    </th>
                    <th className="hidden lg:inline-block  py-4 font-normal text-[#A0AEC0] w-[40%]">
                      FUNCTION
                    </th>
                  </tr>
                </thead>
                <tbody className="w-[100%]">
                  {rankings &&
                    rankings?.map((user, index) => (
                      <tr
                        key={user.id}
                        className="border-b  border-[#ffffff1a] hover:bg-[#ffffff0a] "
                      >
                        <td className="p-[10px_0px] w-[62%]">
                          <div className="flex gap-1 md:gap-0 items-center">
                            <span className="sm:mr-4 text-[#8491A0]">
                              {index + 1}
                            </span>
                            <img
                              src={user?.photoURL || profileImage}
                              alt={user?.displayName}
                              className="w-10 h-10 rounded-lg mr-4"
                            />
                            <div>
                              <div className="text-start text-[12px] lg:text-[16px] font-medium">
                                {user?.pseudo || user?.email?.split('@')[0]}
                              </div>
                            
                            </div>
                          </div>
                        </td>

                        <td className="text-end lg:text-start text-[12px] md:text-[16px] w-[22%]">
                          {user?.totalScore
                            ? parseFloat(user?.totalScore).toFixed(2)
                            : 0}
                        </td>

                        <td className="hidden lg:inline-block lg:text-start mt-4 w-[35%]">
                          <div className="">{user?.fonction || "Employee"}</div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
