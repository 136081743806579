import React from 'react';

// Skeleton for individual reward card
const RewardCardSkeleton = () => (
  <div className="flex lg:flex-col items-center lg:h-[200px] gap-[20px] lg:px-10 py-2 px-6 lg:py-8 rounded-[9.83px] bg-[#ffffff1a] animate-pulse">
    <div className="flex lg:justify-center lg:items-center w-fit lg:w-full">
      {/* Icon placeholder */}
      <div className="w-10 h-10 bg-gray-200/20 rounded-full" />
    </div>
    
    <div className="flex flex-col lg:justify-center lg:items-center w-full gap-2">
      {/* Title placeholder */}
      <div className="h-4 bg-gray-200/20 rounded w-3/4" />
      {/* Description placeholder */}
      <div className="h-3 bg-gray-200/20 rounded w-1/2" />
    </div>
  </div>
);

// Main rewards page skeleton
const MyRewardsSkeleton = () => {
  return (
    <div className="">
      {/* Header Skeleton */}
      <div className="flex flex-col mb-6">
        <div className="flex justify-between items-center">
          <div className="h-8 w-48 bg-gray-200/20 rounded animate-pulse" />
        </div>
      </div>

      {/* Content */}
      <div className="bg-bgColor p-[22px] flex flex-col gap-[30px] rounded-[15px] border border-borderColor">
        {/* Level Progression Header Skeleton */}
        <div className="rewards-content-header">
          <div className="h-7 w-64 bg-gray-200/20 rounded animate-pulse mb-3" />
          <div className="h-5 w-96 bg-gray-200/20 rounded animate-pulse" />
        </div>

        {/* Grid of Reward Cards */}
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-5">
          {/* Generate 6 skeleton cards */}
          {[...Array(6)].map((_, index) => (
            <RewardCardSkeleton key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyRewardsSkeleton;