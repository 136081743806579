import React, { useState, useEffect } from 'react';
import { doc, setDoc, addDoc, collection } from "firebase/firestore"; // Firestore methods
import { db } from '../firebase/config';
import toast from 'react-hot-toast';

const PriceForm = ({ initialData, onSubmit, fetchPrice }) => {
  const [formData, setFormData] = useState({
    productName: 'Chatgpt Academy',
    originalPrice: '',
    discountPercentage: '',
    discountedPrice: '',
    taxPercentage: 0,
    totalPrice: '',
    category: 'Formation IA'
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const calculateDiscountedPrice = (original, discount) => {
    return original - (original * (discount / 100));
  };

  const calculateTotalPrice = (discountedPrice, taxPercentage) => {
    const taxAmount = (discountedPrice * taxPercentage) / 100;
    return discountedPrice + taxAmount;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'discountPercentage' || name === 'taxPercentage') {
      newValue = Math.min(100, Math.max(0, parseFloat(value))) || 0;
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    if (name === 'originalPrice' || name === 'discountPercentage' || name === 'taxPercentage') {
      const original = name === 'originalPrice' ? parseFloat(newValue) : parseFloat(formData.originalPrice);
      const discount = name === 'discountPercentage' ? parseFloat(newValue) : parseFloat(formData.discountPercentage);
      const tax = name === 'taxPercentage' ? parseFloat(newValue) : parseFloat(formData.taxPercentage);

      if (!isNaN(original) && !isNaN(discount) && !isNaN(tax)) {
        const discounted = calculateDiscountedPrice(original, discount);
        const total = calculateTotalPrice(discounted, tax);

        setFormData(prevData => ({
          ...prevData,
          discountedPrice: discounted.toFixed(2),
          totalPrice: total.toFixed(2)
        }));
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (initialData && initialData.id) {
        const docRef = doc(db, 'coursePrice', initialData.id);
        await setDoc(docRef, formData);
      } else {
        await addDoc(collection(db, 'coursePrice'), formData);
      }
      fetchPrice()
      toast.success("Price card saved successfully!", {
        position:"top-center"
      });
      onSubmit(formData);  
    } catch (error) {
      console.error("Error adding or updating document: ", error);
      alert("Failed to save the price card");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6 w-full p-6 bg-[#031A54] rounded-lg shadow-md mx-auto">
      {/* Product Information */}
      <div>
        <h2 className="font-normal text-left text-white text-2xl">Formation Chatgpt Academy</h2>
        <p className="line-through text-left text-white font-normal text-2xl">
          {formData.originalPrice ? `${formData.originalPrice} €` : '999,00 €'}
        </p>
      </div>

      <div>
        <p className="text-left text-white text-2xl">🚨 OFFRE LIMITEE (-{formData.discountPercentage || '52'}%)</p>
        <p className="text-left text-white font-normal text-2xl">
          {formData.discountedPrice ? `${formData.discountedPrice} €` : '487,00 €'}
        </p>
      </div>

      {/* Input Fields */}
      <div className="">
        <label htmlFor="originalPrice" className="block text-left text-sm font-medium text-gray-100 mb-1">
          Original Price (€)
        </label>
        <input
          id="originalPrice"
          name="originalPrice"
          type="number"
          step="0.01"
          value={formData.originalPrice}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="">
        <label htmlFor="discountPercentage" className="block text-left text-sm font-medium text-gray-100 mb-1">
          Discount Percentage (%)
        </label>
        <input
          id="discountPercentage"
          name="discountPercentage"
          type="number"
          value={formData.discountPercentage}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="">
        <label htmlFor="taxPercentage" className="block text-left text-sm font-medium text-gray-100 mb-1">
          Tax Percentage (%)
        </label>
        <input
          id="taxPercentage"
          name="taxPercentage"
          type="number"
          value={formData.taxPercentage}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Calculated Prices */}
      <div className="calPrice w-full">
        <div className="flex justify-between items-center">
          <p className="text-left text-white font-bold text-lg">Chatgpt Academy <br />
            <span className="text-sm font-normal">Formation IA</span>
          </p>
          <p className="font-bold text-left text-white text-lg">
            {formData.discountedPrice ? `${formData.discountedPrice} €` : '487,00 €'}
          </p>
        </div>

        <div className="w-full h-[1px] my-4 bg-white"></div>

        {/* Subtotal and Taxes */}
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <p className="text-left text-white text-lg">Sous-total</p>
            <p className="text-left text-white text-lg">
              {formData.discountedPrice ? `${formData.discountedPrice} €` : '487,00 €'}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-left text-white text-lg">Taxe</p>
            <p className="text-left text-white text-lg">
              {formData.taxPercentage ? `${(formData.discountedPrice * formData.taxPercentage / 100).toFixed(2)} €` : '0,00 €'}
            </p>
          </div>
        </div>

        <div className="w-full h-[1px] my-4 bg-white"></div>

        {/* Total Amount */}
        <div className="flex justify-between items-center">
          <p className="text-left text-white text-lg">Montant total du</p>
          <p className="text-left text-white text-lg">
            {formData.totalPrice ? `${formData.totalPrice} €` : formData.discountedPrice ? `${formData.discountedPrice} €` : '487,00 €'}
          </p>
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        {initialData ? 'Update Price' : 'Create Price'}
      </button>
    </form>
  );
};

export default PriceForm;
