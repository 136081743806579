import React from 'react';

const UserInfoSkeleton = () => {
  return (
    <div className="hidden sm:flex items-center justify-between gap-4 rounded-[15px] py-[17px] px-[22px] bg-bgColor border-[1px] border-borderColor max-h-[100px] animate-pulse">
      {/* Left side - Avatar and User Details */}
      <div className="flex items-center gap-[10px]">
        {/* Avatar skeleton */}
        <div className="rounded-[6px] border-[1px] border-[#191B22] bg-gray-700/50 h-[66px] w-[66px]" />
        
        {/* User details skeleton */}
        <div className="flex flex-col gap-1">
          {/* Name and pseudo skeleton */}
          <div className="flex gap-[4px]">
            <div className="h-4 w-24 bg-gray-700/50 rounded" />
            <div className="h-4 w-20 bg-gray-700/50 rounded" />
          </div>
          
          {/* Email skeleton */}
          <div className="h-4 w-32 bg-gray-700/50 rounded" />
        </div>
      </div>

      {/* Right side - Button skeleton */}
      <div className="w-[296px] h-[50px]">
        <div className="w-full h-full bg-gray-700/50 rounded-lg" />
      </div>
    </div>
  );
};

export default UserInfoSkeleton;