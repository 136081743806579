import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import LogoImage from "../Images/LogoWithText.png";

const CreateNewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validCode, setValidCode] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  // Get the action code from the URL
  const urlParams = new URLSearchParams(location.search);
  const actionCode = urlParams.get('oobCode');

  useEffect(() => {
    const verifyCode = async () => {
      if (!actionCode) {
        setError("Invalid password reset link");
        return;
      }

      try {
        // Verify the password reset code is valid
        await verifyPasswordResetCode(auth, actionCode);
        setValidCode(true);
      } catch (error) {
        setError("Password reset link is invalid or has expired");
        console.error("Error verifying reset code:", error);
      }
    };

    verifyCode();
  }, [auth, actionCode]);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === newPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!passwordMatch || !newPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    if (newPassword.length < 6) {
      setError("Le mot de passe doit comporter au moins 6 caractères");
      return;
    }

    try {
      setLoading(true);
      setError("");
      
      // Complete the password reset
      await confirmPasswordReset(auth, actionCode, newPassword);
      
      // Show success message and redirect
      toast.success("Le mot de passe a été réinitialisé avec succès !");
      navigate("/login");
    } catch (error) {
      console.error("Error resetting password:", error);
      setError(error.message || "Échec de la réinitialisation du mot de passe");
    } finally {
      setLoading(false);
    }
  };

  if (!actionCode) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 to-black">
        <div className="text-white text-center">
          <p className="text-xl mb-4">Lien de réinitialisation de mot de passe invalide</p>
          <Link to="/login" className="text-blue-400 hover:text-blue-300">
            Revenir à la connexion
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col items-center bg-gradient-to-br from-blue-900 to-black">
      <div className="mb-10 flex items-center gap-2 mt-10">
        <div className="flex flex-col text-white font-normal">
        <img src={LogoImage} width={180} height={130} alt="Logo" />
        </div>
      </div>
      <div className="w-[90%] md:max-w-[600px] h-fit md:h-[500px] px-8 py-1 border border-gray-800 bg-[#121937] rounded-xl shadow-lg">
        <Link to="/login" className="text-gray-300 hover:text-white">
          <div className="flex justify-end items-center gap-1">
            <X />
            <span>Fermer</span>
          </div>
        </Link>
        
        <div className="my-10 mt-14">
          <h2 className="text-2xl text-gray-200 font-bold text-center mb-3">
            Créer un nouveau mot de passe
          </h2>
          <p className="text-gray-500">
            Saisis ton nouveau mot de passe ci-dessous pour réinitialiser ton accès.
          </p>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-500 bg-opacity-10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-[16px] text-start font-medium text-gray-300">
              Nouveau mot de passe
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className="w-full focus:shadow-glow px-3 py-[10px] mt-1 text-gray-200 bg-[#2a304b] border border-transparent focus:border-blue-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="*******"
              required
              minLength={6}
            />
          </div>
          <div>
            <label className="block text-[16px] text-start font-medium text-gray-300">
              Confirme ton mot de passe
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className="w-full focus:shadow-glow px-3 py-[10px] mt-1 text-gray-200 bg-[#2a304b] border border-transparent focus:border-blue-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="*******"
              required
              minLength={6}
            />
            {!passwordMatch && confirmPassword && (
              <p className="text-red-500 text-sm mt-1">
                Les mots de passe ne correspondent pas.
              </p>
            )}
          </div>
          <button
            type="submit"
            disabled={loading || !validCode}
            className="flex shadow-glow w-full py-[10px] px-5 rounded-md justify-center items-center gap-2.5 bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] text-white text-base font-medium text-center mt-7 text-[0.890rem] sm:text-[1rem] disabled:opacity-50"
          >
            {loading ? "Mise à jour..." : "Enregistrer le mot de passe"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateNewPassword;