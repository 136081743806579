import React, { useEffect, useRef, useState } from "react";
import { usePlyr } from "plyr-react";
import "plyr-react/plyr.css";

const CustomPlyrInstance = React.forwardRef((props, ref) => {
  const {
    source,
    options,
    handleVideoComplete,
    sectionId,
    moduleId,
    currentItem,
    startTime,
  } = props;
  const raptorRef = usePlyr(ref, { options, source });


  useEffect(() => {
    const { current } = ref;
    if (current?.plyr?.source === null) return;
    const api = current;

    // Load saved time on ready
    api.plyr.on("ready", () => {
      // console.log("StartTime: ", startTime);
      // api.plyr.currentTime = Math.max(0, startTime - 10);
    });

    // Save time when video is paused
    api.plyr.on("pause", () => {
      localStorage.setItem(
        `video-progress-${currentItem.id}`,
        JSON.stringify(api.plyr.currentTime)
      );
    });

    // Save time when video is played
    api.plyr.on("play", () => {
        const currTime = JSON.parse(localStorage.getItem(`video-progress-${currentItem.id}`));
        api.plyr.currentTime = Math.max(0, (currTime || startTime));
    });

    // Save time periodically during playback
    api.plyr.on("timeupdate", () => {
        localStorage.setItem(
          `video-progress-${currentItem.id}`,
          JSON.stringify(api.plyr.currentTime)
        );
    });

    // Clear progress when video ends
    api.plyr.on("ended", () => {
      localStorage.removeItem(`video-progress-${currentItem.id}`);
      handleVideoComplete(currentItem, sectionId, moduleId);
    });
  });

  return <video ref={raptorRef} className="plyr-react plyr" playsInline />;
});

const Player = ({
  videoSource,
  currentItem,
  handleVideoEnd,
  moduleId,
  sectionId,
  plyrOptions,
}) => {
  const ref = useRef(null);

  const [isMobile, setIsMobile] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);
  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    console.log("Key: ", `video-progress-${currentItem?.id}`)
    const savedProgress = JSON.parse(localStorage.getItem(
      `video-progress-${currentItem?.id}`)
    );
    setStartTime(savedProgress)

    var safari = /^((?!chrome|android).)safari/i.test(navigator?.userAgent);
    var firefox = /^((?!chrome|android).)firefox/i.test(navigator?.userAgent);

    const checkMobile = () => {
      const ua = navigator.userAgent;
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          ua
        )
      );
    };

    if (safari) setIsSafari(true);
    if (firefox) {
      setIsFirefox(firefox);
    }
    checkMobile();
  }, [isSafari, currentItem?.id]);

  let adjustedVideoSource = isSafari ? [videoSource[0]] : videoSource;
  adjustedVideoSource = isFirefox
    ? [videoSource[1], videoSource[2]]
    : adjustedVideoSource;

  const adjustedPlyrOptions = {
    ...plyrOptions,
    fullscreen: { enabled: true, fallback: true, iosNative: true },
    settings: plyrOptions?.settings?.filter((setting) =>
      isSafari ? setting !== "quality" : setting
    ),
  };

  if (isMobile) {
    adjustedPlyrOptions.controls = [
      "play-large",
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "settings",
      "pip",
      "airplay",
      "fullscreen",
    ];
  }

  return (
    <div className="wrapper">
      {adjustedVideoSource && (
        <CustomPlyrInstance
          ref={ref}
          source={{
            type: "video",
            sources: adjustedVideoSource,
            poster: currentItem?.thumbnailUrl,
          }}
          options={adjustedPlyrOptions}
          handleVideoComplete={handleVideoEnd}
          moduleId={moduleId}
          sectionId={sectionId}
          currentItem={currentItem}
          startTime={startTime}
        />
      )}
    </div>
  );
};

export default Player;
