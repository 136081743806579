import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTable, useExpanded } from "react-table";
import {  updateDoc,doc, onSnapshot, collection } from "firebase/firestore";
import { db } from "../firebase/config";
import { ChevronDown, ChevronRight, ArrowUp, ArrowDown } from "lucide-react";

const Course = () => {
  const [collections, setCollections] = useState({
    sections: [],
    modules: [],
    videos: [],
    quizes: []
  });
  const [expanded, setExpanded] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeToCollections();
    return () => unsubscribe();
  }, []);

  const subscribeToCollections = () => {
    const collectionNames = ['sections', 'modules', 'videos', 'quizes'];
    const unsubscribes = collectionNames.map(collectionName =>
      onSnapshot(collection(db, collectionName), (snapshot) => {
        const collectionData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCollections(prev => ({ ...prev, [collectionName]: collectionData }));
      }, (error) => {
        console.error(`Error in ${collectionName} subscription:`, error);
        setError(`An error occurred while fetching ${collectionName}. Please try again.`);
      })
    );

    return () => unsubscribes.forEach(unsubscribe => unsubscribe());
  };

  const formattedData = useMemo(() => {
    const { sections, modules, videos, quizes } = collections;
    return sections.map((section) => ({
      ...section,
      subRows: modules
        .filter((module) => module.section?.sectionId === section.id)
        .map((module) => ({
          ...module,
          subRows: [
            ...videos
              .filter((video) => video.module?.moduleId === module.id)
              .map((video) => ({ ...video, type: "video" })),
            ...quizes
              .filter((quiz) => quiz.module?.moduleId === module.id)
              .map((quiz) => ({ ...quiz, type: "quiz" })),
          ].sort((a, b) => (a.order || 0) - (b.order || 0)),
        }))
        .sort((a, b) => (a.order || 0) - (b.order || 0)),
    })).sort((a, b) => (a.order || 0) - (b.order || 0));
  }, [collections]);

  const moveItem = useCallback(async (row, direction) => {
    const { original, depth } = row;
    let parentRows, collectionName;
    if (depth === 1) {
      return
    } else if (depth === 2) {
      const parentSection = formattedData.find(section => 
        section.subRows.some(module => module.id === original.module.moduleId)
      );
      parentRows = parentSection.subRows.find(module => module.id === original.module.moduleId).subRows;
      collectionName = original.type === 'video' ? 'videos' : 'quizes';
    } else {
      console.error("Cannot move items at this level");
      return;
    }

    const currentIndex = parentRows.findIndex(item => item.id === original.id);
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    if (newIndex < 0 || newIndex >= parentRows.length) return;

    const itemOrder = direction === 'up' ? +original.order - 1 : +original.order + 1;

    try {
      await updateDoc(doc(db, collectionName, original.id), { order: itemOrder });
      setExpanded(prevExpanded => ({
        ...prevExpanded,
        [row.id]: true
      }));

    } catch (err) {
      console.error("Error updating order:", err);
      setError("An error occurred while updating the order. Please try again.");
    }
  }, [formattedData]);
  const columns = useMemo(
    () => [
      {
        
        Header: "Title",
        accessor: "title",
        Cell: ({ row, value }) => (
          <span
            style={{
              paddingLeft: `${row.depth * 20}px`,
              fontWeight: row.depth === 0 ? "bold" : "normal",
              color:
                row.depth === 2
                  ? row.original.type === "video"
                    ? "#4CAF50"
                    : "#FFC107"
                  : "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: { marginRight: "5px" },
                })}
              >
                {row.isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              </span>
            ) : null}
            {row.depth === 2 ? `${row.original.type}: ${value}` : value}
          </span>
        ),
      },
      {
        Header: "Order",
        accessor: "order",
        Cell: ({ value }) => value || "",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          row.depth > 1 && (
            <div>
              <button onClick={() => moveItem(row, "up")} style={{ marginRight: "5px" }}>
                <ArrowUp size={16} />
              </button>
              <button onClick={() => moveItem(row, "down")}>
                <ArrowDown size={16} />
              </button>
            </div>
          )
        ),
      },
    ],
    []
  );

 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded: tableExpanded },
  } = useTable(
    {
      columns,
      data: formattedData,
      initialState: { expanded },
      autoResetExpanded: false,
    },
    useExpanded
  );
  useEffect(() => {
    setExpanded(tableExpanded);
  }, [tableExpanded]);

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  return (
    <div style={{ backgroundColor: "#060B26", padding: "20px", borderRadius: "8px" }}>
      <table {...getTableProps()} style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0 4px" }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ color: "white", textAlign: "left", padding: "10px" }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()} style={{ color: "white", padding: "10px" }}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Course;