import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import {
    TrashIcon,
    PencilIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import toast from "react-hot-toast";
import CouponsForm from "./CouponsForm";

const Coupons = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const [coupons, setCoupons] = useState([]);
    const [filteredCoupons, setFilteredCoupons] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { deleteCoupon } = useFirebase();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [couponsPerPage] = useState(10);

    useEffect(() => {
        const couponCollection = collection(db, "coupons");
        const unsubscribe = onSnapshot(couponCollection, (snapshot) => {
            const couponList = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCoupons(couponList);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const results = coupons.filter(
            (coupon) =>
                coupon.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCoupons(results);
        setCurrentPage(1); // Reset to first page when search results change
    }, [searchTerm, coupons]);

    const handleAdd = () => {
        setSelectedCoupon(null);
        setIsModalOpen(true);
    };

    const handleUpdate = (coupon) => {
        setSelectedCoupon(coupon);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCoupon(null);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this coupon?")) {
            await deleteCoupon(id);
            toast.success("Coupon deleted successfully...", {
                position: "top-center",
            });
        }
    };

    // Pagination logic
    const indexOfLastCoupon = currentPage * couponsPerPage;
    const indexOfFirstCoupon = indexOfLastCoupon - couponsPerPage;
    const currentCoupons = filteredCoupons.slice(
        indexOfFirstCoupon,
        indexOfLastCoupon
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="w-full rounded-xl h-full">
            <div className="flex flex-col">
                <Header
                    PageName={"coupons"}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
            </div>
            <div className="p-6 bg-[#060B26] rounded-xl border-[#ffffff55] border">
                <div className="w-full p-6 bg-[#060B26] rounded-xl">
                    <div className="flex justify-between items-center mb-4">
                        <input
                            type="text"
                            placeholder="Search coupons..."
                            className="px-4 py-2 border rounded-md bg-[#1C2536] text-white"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button
                            onClick={handleAdd}
                            className="px-4 py-2 bg-primaryBlue text-white rounded-md"
                        >
                            Add Coupon
                        </button>
                    </div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-[#1c253600]">
                            <tr>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Title
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Discount type
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Value
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Limited to
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Usage Limit
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Created At
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Expiry Date
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-[#060B26] divide-y divide-gray-700">
                            {currentCoupons?.map((coupon) => (
                                <tr key={coupon.id}>
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {coupon.title}
                                    </td>
                                    <td
                                        className="text-left py-4 whitespace-nowrap text-sm text-white"
                                    >
                                        {coupon.discountType}
                                    </td>
                                    <td
                                        className="text-left py-4 whitespace-nowrap text-sm text-white"
                                    >
                                        {coupon.value}
                                    </td>
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {coupon.limitedTo}
                                    </td>
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {coupon.usageLimit}
                                    </td>
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {new Date(coupon.createdAt?.toDate()).toLocaleString()}
                                    </td>
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {new Date(coupon?.expiry.toDate()).toLocaleString()}
                                    </td>
                                    <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                                        <button
                                            onClick={() => handleUpdate(coupon)}
                                            className="text-indigo-600 hover:text-indigo-900 mr-2"
                                        >
                                            <PencilIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(coupon.id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="mt-6 flex flex-col items-center">
                        <div className="flex items-center justify-center space-x-2">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ChevronLeftIcon className="h-5 w-5" />
                            </button>
                            {[
                                ...Array(
                                    Math.ceil(filteredCoupons.length / couponsPerPage)
                                ).keys(),
                            ].map((number) => (
                                <button
                                    key={number + 1}
                                    onClick={() => paginate(number + 1)}
                                    className={`px-3 py-2 rounded-md text-sm font-medium ${currentPage === number + 1
                                            ? "bg-primaryBlue text-white"
                                            : "bg-[#1C2536] text-white hover:bg-[#2C3546]"
                                        }`}
                                >
                                    {number + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                    currentPage ===
                                    Math.ceil(filteredCoupons.length / couponsPerPage)
                                }
                                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ChevronRightIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                </div>

                {isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                        <CouponsForm coupon={selectedCoupon} onClose={handleCloseModal} />
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default Coupons;
