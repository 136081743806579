import React from 'react'

//styles
import './ResumeTraining.css'

import CourseContent from '../ResumeTrainingPage/CourseContent'
export default function ResumeTraining({ setIsSidebarOpen, isSidebarOpen }) {
  return (
    <div className="flex flex-1 gap-[1.5rem]">
      <div className='w-full h-screen flex flex-col'>
        <CourseContent isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
    </div>
  )
}
