import React from 'react';

const DonutChartSkeleton = () => {
  return (
    <div className="relative lg:w-[130px] lg:h-[130px] w-[90px] h-[90px] m-3 flex flex-col items-center">
      {/* Circular skeleton for donut chart */}
      <div className="relative">
        <div className="rounded-full bg-gray-700/50 lg:w-[130px] lg:h-[130px] w-[90px] h-[90px] animate-pulse" />
        {/* Center percentage skeleton */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="h-6 w-12 bg-gray-600/50 rounded animate-pulse" />
        </div>
      </div>
      
      {/* Title and description skeletons */}
      <div className="text-content mt-2 flex flex-col items-center gap-1 w-full">
        <div className="h-3 w-20 bg-gray-700/50 rounded animate-pulse" />
        <div className="h-2 w-24 bg-gray-600/50 rounded animate-pulse" />
      </div>
    </div>
  );
};

const DonutSectionSkeleton = () => {
  // Array to represent loading charts
  const loadingCharts = [1, 2, 3];

  return (
    <div className="2xl:w-[44%] 2xl:h-[380px]">
      {/* Desktop View */}
      <div className="hidden rounded-[15px] justify-evenly h-[380px] sm:flex flex-col sm:w-full gap-4 text-white border border-white/[0.3] bg-black/[0.65] p-[17px] px-[22px] bg-[#050E37]">
        {/* Header skeleton */}
        <div className="header">
          <div className="h-6 w-48 bg-gray-700/50 rounded animate-pulse mb-2" />
          <div className="h-4 w-64 bg-gray-600/50 rounded animate-pulse" />
        </div>

        {/* Pie chart section */}
        <div className="pie-chart">
          <div className="gap-[4px] rounded-[15px] flex text-white w-full overflow-x-auto no-scrollbar">
            {loadingCharts.map((item) => (
              <div
                key={item}
                className="flex rounded p-2 gap-4 w-full flex-col items-center justify-center"
              >
                <DonutChartSkeleton />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <h2 className="sm:hidden text-white my-2 mb-4 text-[16px] font-semibold text-left">
        <div className="h-5 w-32 bg-gray-700/50 rounded animate-pulse" />
      </h2>
      <div className="sm:hidden 2xl:h-[380px] rounded-[15px] flex gap-4 text-white w-full overflow-x-auto no-scrollbar">
        {loadingCharts.map((item) => (
          <div
            key={item}
            className="border border-white/[0.3] bg-black/[0.65] p-2 bg-[#050E37] rounded-[15px]"
          >
            <div className="flex rounded gap-4 w-full flex-col items-center justify-center">
              <DonutChartSkeleton />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DonutSectionSkeleton;