//styles

import "./CourseLeftNav.css";
//page component
import CourseNavItem from "./CourseNavItem";


import React, { useState } from "react";
import { useGlobal } from "../context/GlobalContext";

export default function CourseLeftNav({ setIsMobile }) {
  const { courseData, userProgress } = useGlobal();
  const [openItemId, setOpenItemId] = useState(userProgress?.currentSection?.id);

  const handleClickNavItem = (id) => {
    setOpenItemId((prevId) => (prevId === id ? null : id));
  };


  return (
    <>

      <div className="course-nav-wrapper w-auto">
        <div className="nav-top">
          <h2 className="text-[20px] font-semibold text-left text-white">
            Sommaire
          </h2>
        </div>

        <div className="flex gap-[8px] flex-col">
          {courseData?.map((section) => (
            <div key={section.id}>
              <CourseNavItem
                section={section}
                setIsMobile={setIsMobile}
                courseModules={section.modules}
                courseModuleNumber={section.id}
                isOpen={openItemId === section.id}
                colorStyle={
                  section.order === 1
                    ? "#BCC1CB"
                    : section.order === 2
                      ? "#92EACA"
                      : "#FCD996"
                }
                onClick={() => handleClickNavItem(section.id)}
              />
            </div>
          ))}
        </div>
      </div>
    </>

  );
}
