import React from 'react';

const EventCardSkeleton = () => {
  return (
    <div className="flex p-[10px] gap-[12px] rounded-[9.83px] bg-[#ffffff1a] animate-pulse">
      {/* Icon placeholder */}
      <div className="w-[50px] rounded-[12px] bg-gray-700/50 h-[37px] sm:min-w-[47px] sm:h-[47px]" />

      <div className="w-[95%] flex flex-col">
        {/* Header with date */}
        <div className="flex justify-between items-start">
          <div className="h-4 w-24 bg-gray-700/50 rounded" />
          <div className="h-4 w-20 bg-gray-700/50 rounded" />
        </div>

        {/* Content placeholders - hidden on mobile */}
        <div className="w-full mt-2">
          <div className="hidden md:block space-y-2">
            <div className="h-3 w-3/4 bg-gray-700/50 rounded" />
            <div className="h-3 w-2/3 bg-gray-700/50 rounded" />
            <div className="h-3 w-3/4 bg-gray-700/50 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};

const EventSectionSkeleton = () => {
  // Array to represent loading events
  const loadingEvents = [1, 2, 3, 4, 5];

  return (
    <div className="2xl:w-[28%]">
      {/* Mobile header */}
      <div className="flex items-center justify-between lg:hidden mb-4">
        <div className="h-5 w-24 bg-gray-700/50 rounded animate-pulse" />
        <div className="h-4 w-16 bg-gray-700/50 rounded animate-pulse" />
      </div>

      {/* Main container */}
      <div className="flex w-full flex-col text-white gap-[30px] h-[380px] overflow-y-hidden rounded-[15px] bg-[#000000a6] border-[1px] border-[#ffffff4d] pt-[17px] pr-[22px] pb-0 pl-[22px]">
        {/* Desktop header */}
        <div className="hidden lg:block">
          <div className="h-6 w-32 bg-gray-700/50 rounded animate-pulse" />
        </div>

        {/* Event list */}
        <div className="event-list flex flex-col gap-[15px] overflow-scroll pb-[15px]">
          {loadingEvents.map((item) => (
            <EventCardSkeleton key={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventSectionSkeleton;