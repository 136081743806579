import React, { useState, useEffect } from 'react';
import { useFirebase } from '../context/firebaseContext';
import useUserRole from '../hooks/useUserRole';
import toast from 'react-hot-toast';

const SectionForm = ({ section, onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    levelName: '',
    createdBy: '',
    type: "section",
    order: null,
    status: 'inactive', // Default value
    modules: []
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { addSection, updateSection } = useFirebase();
  const { user } = useUserRole();

  useEffect(() => {
    if (section) {
      setFormData({
        title: section.title,
        description: section.description,
        levelName: section.levelName,
        createdBy: user?.email.split('@')[0],
        type: section.type,
        order: +section.order,
        status: section.status || 'locked' // Default to 'locked' if no status is provided
      });
    }
  }, [section, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      status: value
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!formData.levelName) newErrors.levelName = 'Level name is required';
    if (!formData.order) newErrors.order = 'Order is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const sectionData = {
          title: formData.title,
          description: formData.description,
          levelName: formData.levelName,
          createdBy: user?.email.split('@')[0],
          type: formData.type,
          order: +formData.order,
          status: formData.status
         
        };

        if (section) {
          const res = await updateSection(section.id, sectionData);
          res.status === "success" ? toast.success(res.message, { position: 'top-center' }) : toast.error(res.message, { position: 'top-center' });
        } else {
          const res = await addSection('sections', sectionData);
          res.status === "success" ? toast.success(res.message, { position: 'top-center' }) : toast.error(res.message, { position: 'top-center' });
        }

        onClose();
      } catch (error) {
        toast.error(error.message || 'Error occurred', { position: 'top-center' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border">
      <h2 className="text-xl text-start font-medium text-white py-4 border-b mb-6">
        {section ? 'Update Section' : 'Add Section'}
      </h2>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="title" className="text-lg font-normal text-white mb-1">Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Section Title"
        />
        {errors.title && <p className="mt-1 text-sm text-red-500">{errors.title}</p>}
      </div>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="description" className="text-lg font-normal text-white mb-1">Description</label>
        <input
          type="text"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Section Description"
        />
        {errors.description && <p className="mt-1 text-sm text-red-500">{errors.description}</p>}
      </div>
      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="order" className="text-lg font-normal text-white mb-1">Order</label>
        <input
          type="number"
          id="order"
          name="order"
          value={formData.order}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Section order"
        />
        {errors.order && <p className="mt-1 text-sm text-red-500">{errors.order}</p>}
      </div>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="levelName" className="text-lg font-normal text-white mb-1">Level</label>
        <input
          type="text"
          id="levelName"
          name="levelName"
          value={formData.levelName}
          onChange={handleChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Level"
        />
        {errors.levelName && <p className="mt-1 text-sm text-red-500">{errors.levelName}</p>}
      </div>

      <div className='mb-4 flex flex-col items-start'>
        <label htmlFor="status" className="text-lg font-normal text-white mb-1">Status</label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleStatusChange}
          className="w-full py-4 px-3 rounded-md border border-[#ffffff1a] bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="locked" className='text-black'>Locked</option>
          <option value="inprogress" className='text-black'>In progress</option>
          <option value="unlocked" className='text-black'>Unlocked</option>
          <option value="inactive" className='text-black'>Inactive</option>
          <option value="completed" disabled className='text-gray-500' >Completed</option>
        </select>
      </div>

      <div className='flex'>
        <button
          type="submit"
          disabled={isLoading}
          className={`px-6 py-2 bg-primaryBlue text-white rounded-lg font-normal ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primaryBlue'}`}
        >
          {isLoading ? 'Processing...' : (section ? 'Update Section' : 'Add Section')}
        </button>
      </div>
    </form>
  );
};

export default SectionForm;
