import React from 'react'
// import './ResumeTraining.css'
import ForwardIcon from "../Images/forward.svg"

//react hooks
import { Link } from 'react-router-dom'
import useUserRole from '../hooks/useUserRole'

export default function ResumeTraining() {
  const {user} =useUserRole()
  return (
    <div className='flex-col 2xl:w-[28%] justify-between flex p-5 lg:p-[36px] px-[22px] rounded-[15px] border border-[#ffffff4d] bg-[#000000a6]'>
      <div className="flex flex-col gap-1">
        <div className=''>
          <h2 className='leading-[110%] text-white text-[24px] font-semibold text-left' style={{ letterSpacing: '-0.03em' }}>Ravi de vous
            retrouver !</h2>
        </div>

        <div className='flex flex-col gap-1 mt-1'>
          <h2 className='text-left text-[14px] text-[#A0AEC0]'>{`Un plaisir de te revoir ${user?.displayName || user?.email?.split("@")[0]}`}</h2>
        </div>

      </div>

      <div className="flex gap-[2px]">
        <Link to={"/resume"} className='flex gap-1'>
          <h3 className='text-[12px] font-white font-medium text-left'>Reprendre la formation</h3>
          <img src={ForwardIcon} alt="" />
        </Link>

      </div>

    </div>
  )
}
