import React from 'react';

const CourseCardSkeleton = () => {
  return (
    <div className="flex flex-col justify-between gap-10 p-6 rounded-lg bg-gray-900/50 animate-pulse">
      {/* Tag skeleton */}
      <div className="flex flex-col gap-6">
        <div className="h-6 w-24 bg-gray-700/50 rounded-full" />
        
        {/* Title and description skeleton */}
        <div className="flex flex-col gap-2 w-full">
          <div className="h-4 w-3/4 bg-gray-700/50 rounded" />
          <div className="h-3 w-full bg-gray-700/50 rounded" />
        </div>
      </div>
      
      {/* Button skeleton */}
      <div className="w-full h-10 bg-gray-700/50 rounded-lg" />
    </div>
  );
};

const CoursesSectionSkeleton = () => {
  return (
    <div className="w-full flex p-[17px_22px] flex-col items-start gap-[30px] rounded-[15px] bg-[#000000a6] border-[1px] border-[#ffffff4d]">
      {/* Section title skeleton */}
      <div className="w-full">
        <div className="h-6 w-40 bg-gray-700/50 rounded mb-2" />
        <div className="h-4 w-64 bg-gray-700/50 rounded" />
      </div>

      {/* Grid of skeleton cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <CourseCardSkeleton key={item} />
        ))}
      </div>
    </div>
  );
};

export default CoursesSectionSkeleton;